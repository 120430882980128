import {
  QueryClient,
  type FetchInfiniteQueryOptions,
  type UseQueryOptions,
} from '@tanstack/react-query';

export async function prepopulateCache<T>(
  queryClient: QueryClient,
  query: UseQueryOptions<T>,
): Promise<null> {
  const dataInCache = !!queryClient.getQueryData(query.queryKey);

  if (!dataInCache) {
    // Populate cache
    await queryClient.prefetchQuery(query);
  }

  return Promise.resolve(null);
}

export async function prepopulateInfiniteQueryCache<T>(
  queryClient: QueryClient,
  query: FetchInfiniteQueryOptions<T>,
): Promise<null> {
  const dataInCache = !!queryClient.getQueryData(query.queryKey);

  if (!dataInCache) {
    // Populate cache
    await queryClient.prefetchInfiniteQuery(query);
  }

  return Promise.resolve(null);
}
