export const initialPageIndex = 1;
export const initialPageSize = 500;

export const escalateAfterOptions = [
  { value: '15', label: '15 min' },
  { value: '30', label: '30 min' },
  { value: '45', label: '45 min' },
  { value: '60', label: '1 hour' },
  { value: '120', label: '2 hours' },
  { value: '240', label: '4 hours' },
];

export const defaultEscalateAfterValue = 30;
