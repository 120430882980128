import { type ReactElement, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAccessControlGuard } from '$shared/custom-hooks/useAccessControlGuard';
import { useTokenAttributes } from '$shared/custom-hooks/useTokenAttributes';

import { ConfirmDeleteNotiRuleDialog } from '$features/notifications/detail/confirm-delete-noti-rule-dialog';
import { DeleteNotificationRuleErrorDialog } from '$features/notifications/detail/delete-notification-rule-error-dialog';
import { DeleteNotificationRuleSuccessDialog } from '$features/notifications/detail/delete-notification-rule-success-dialog';
import { NotificationDevicesInfo } from '$features/notifications/detail/notification-detail-devices';
import { NotificationDetailFooter } from '$features/notifications/detail/notification-detail-footer';
import { NotificationDetailHeader } from '$features/notifications/detail/notification-detail-header';
import { useArchiveNotificationRule } from '$features/notifications/notification-rules.api';
import { NotificationRuleDetailData } from '$features/notifications/types';

import { NotificationDetailCondition } from './notification-detail-condition';
import { NotificationDetailCreatedInfo } from './notification-detail-created-info';
import { NotificationDetailRecipient } from './notification-detail-recipient';

export interface NotificationRuleDetailProps {
  data: NotificationRuleDetailData;
  onEditDialogOpen: () => void;
}

export function NotificationRuleDetail({
  data,
  onEditDialogOpen,
}: NotificationRuleDetailProps): ReactElement {
  const [presentingConfirmDeleteDialog, setPresentingConfirmDeleteDialog] =
    useState(false);
  const [presentingDeleteSuccessDialog, setPresentingDeleteSuccessDialog] =
    useState(false);
  const [presentingRetryDeleteDialog, setPresentingRetryDeleteDialog] = useState(false);

  const navigate = useNavigate();

  const errorsFound = data.devices.errors.length > 0;

  const handleArchiveNotificationRule = useCallback(() => {
    setPresentingConfirmDeleteDialog(true);
  }, []);

  const { mutateAsync } = useArchiveNotificationRule();

  const handleConfirmDelete = useCallback(async () => {
    setPresentingConfirmDeleteDialog(false);
    try {
      await mutateAsync(data.id);
      setPresentingRetryDeleteDialog(false);
      setPresentingDeleteSuccessDialog(true);
    } catch (error) {
      // eslint-disable-next-line no-console -- for debugging
      console.error(error);
      setPresentingRetryDeleteDialog(true);
    }
  }, []);

  const handleSuccessDialogClose = useCallback((open: boolean) => {
    if (!open) {
      setPresentingDeleteSuccessDialog(false);
      navigate('/notifications/notifications-rules');
    }
  }, []);

  const { email: emailFromToken } = useTokenAttributes(['email']);
  const canArchiveOwn = useAccessControlGuard('CHANGE_LIFECYCLE_OWN_NOTIFICATION_RULES');
  const canArchiveAll = useAccessControlGuard(
    'CHANGE_LIFECYCLE_OTHERS_NOTIFICATION_RULES',
  );
  const canArchive =
    (canArchiveOwn && data?.createdBy === emailFromToken) || canArchiveAll;

  return (
    <>
      <div
        className="flex flex__ai--center m-bottom-xxxl"
        data-testid="device-detail-header"
      >
        <NotificationDetailHeader className="m-right-m" notificationRule={data} />
      </div>
      <div className="box flex__dir--column">
        <NotificationDevicesInfo
          devices={data.devices.details}
          fetchError={errorsFound}
        />
        <NotificationDetailCondition tags={data.tags} />
        <NotificationDetailRecipient
          escalationLayers={data.escalationLayers || []}
          tags={data.tags}
        />
        <NotificationDetailCreatedInfo data={data} />
        <NotificationDetailFooter
          canArchive={canArchive}
          onEditDialogOpen={onEditDialogOpen}
          onArchiveNotificationRule={handleArchiveNotificationRule}
        />

        <ConfirmDeleteNotiRuleDialog
          isOpen={presentingConfirmDeleteDialog}
          onClose={() => setPresentingConfirmDeleteDialog(false)}
          onConfirmDelete={handleConfirmDelete}
          name={data.name}
        />

        <DeleteNotificationRuleSuccessDialog
          isOpen={presentingDeleteSuccessDialog}
          onOpenChange={handleSuccessDialogClose}
          name={data.name}
        />

        <DeleteNotificationRuleErrorDialog
          isOpen={presentingRetryDeleteDialog}
          onOpenChange={setPresentingRetryDeleteDialog}
          onTryAgain={handleConfirmDelete}
        />
      </div>
    </>
  );
}
