import {
  EntityType,
  type EnrichedEntity,
  type EnrichedUser,
} from '@eppendorf/vnls-audit-event-client';
import { UserRole } from '@eppendorf/vnls-user-tenant-utils';
import { useTranslation } from 'react-i18next';

interface PerformedByCellProps {
  entity: EnrichedEntity;
}

export function PerformedByCell({ entity }: PerformedByCellProps) {
  const { t } = useTranslation();
  const userRole = {
    [UserRole.LabAdmin]: t('userManagement.roles.labAdmin'),
    [UserRole.LabMember]: t('userManagement.roles.labMember'),
  }[(entity as EnrichedUser)?.roles?.[0] as UserRole];

  const getEntityName = () => {
    switch (entity?.type) {
      case EntityType.User:
        return `${entity.firstName} ${entity.lastName}`;
      case EntityType.Device:
      case EntityType.NotificationRule:
        return entity.name;
      default:
        return t('auditEvents.notAvailable');
    }
  };

  return (
    <div className="whitespace-nowrap ellipsize">
      {getEntityName()}
      {userRole && <div>{userRole}</div>}
    </div>
  );
}
