import { t } from 'i18next';

export const renderEscalateAfter = (escalateAfter: number) => {
  let value;

  if (escalateAfter === 60) {
    value = `1 ${t('shared.hour')}`;
  } else if (escalateAfter > 60) {
    value = `${escalateAfter / 60} ${t('shared.hours')}`;
  } else {
    value = `${escalateAfter} ${t('shared.min')}`;
  }

  return value;
};
