import {
  getFormattedLocalizedDateNoTime,
  getLocalizedTimeWithSeconds,
} from '$shared/utils/date.utils';

interface CellTimestampProps {
  timestamp: string | number | null | undefined;
}

export function CellTimestamp({ timestamp }: CellTimestampProps) {
  return (
    <div className="flex flex__dir--column">
      <time className="font-weight-bold">
        {getFormattedLocalizedDateNoTime(timestamp ?? '')}
      </time>
      <time>{getLocalizedTimeWithSeconds(timestamp ?? '')}</time>
    </div>
  );
}
