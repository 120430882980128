import type {
  PaginationResponse,
  Pagination as PaginiationType,
} from '@eppendorf/vnls-inventory-service-types';
import {
  Button,
  Icon,
  IconSizeClasses,
  SortableTable,
  useDidMountEffect,
} from '@eppendorf/vnls-react-components';
import type { Device } from '@eppendorf/vnls-telemetry-and-events-types';
import { createColumnHelper } from '@tanstack/react-table';
import { type ReactElement, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useRouteError, useSearchParams } from 'react-router-dom';

import { DeviceNameCell } from '$components/device/name-cell';
import { DoorOrLidStatusWrapper } from '$components/door-or-lid-status/door-or-lid-status-wrapper';
import { LoadingErrorHint } from '$components/loading-error-hint/loading-error-hint';
import { Overview } from '$components/overview-box/overview-box';
import { SearchInput } from '$components/search-input/search-input';
import { Pagination } from '$components/table/pagination/pagination';
import { ConnectionStatusBadge } from '$features/devices/connection-status-badge';
import { ActivityStatusWrapper } from '$features/monitoring/activity-status';
import { UnitAlertState } from '$features/monitoring/alert-status/unit-alert-state';
import {
  subscribeToRealtimeDevices,
  useRealtimeDevices,
} from '$features/monitoring/monitoring.graphql.api';
import { UnlessOffline } from '$features/monitoring/unless-offline';
import { isValueWithKeyInSearchParams } from '$features/navigation/isValueWithKeyInSearchParams';

export function MonitoringList(): ReactElement {
  const [{ page, pageSize }, setPagination] = useState<PaginiationType>({
    page: 1,
    pageSize: 10,
  });

  const [searchQuery, setSearchQuery] = useState<string>('');

  const handleSearchQueryChange = useCallback(
    (value: string) => {
      setSearchQuery(value);
      setPagination({ page: 1, pageSize });
    },
    [pageSize],
  );

  const { data, isError, isLoading, refetch } = useRealtimeDevices(
    page,
    pageSize,
    'paired',
    searchQuery,
  );
  const routeError = useRouteError();

  subscribeToRealtimeDevices();

  const { t } = useTranslation();
  const navigate = useNavigate();

  function handleRowClick(realtimeDevice: Device) {
    if (!realtimeDevice) return;
    navigate({
      search: `?sidecarRoute=monitoring/parameters&manufacturer=${realtimeDevice.manufacturer}&serialNumber=${realtimeDevice.serialNumber}`,
    });
  }

  const columnHelper = createColumnHelper<Device>();

  const columns = useMemo(
    () => [
      columnHelper.accessor('realtime', {
        header: () => t('monitoring.activityStatus'),
        id: 'activityStatus',
        // eslint-disable-next-line react/no-unstable-nested-components -- accepted
        cell: (info) => (
          <ActivityStatusWrapper
            realtimeArray={info.getValue()}
            fallback={
              <ConnectionStatusBadge status={info.row?.original?.connectionStatus} />
            }
          />
        ),
      }),
      columnHelper.accessor('name', {
        header: () => t('monitoring.device'),
        id: 'device',
        // eslint-disable-next-line react/no-unstable-nested-components -- accepted until we figure out a better way
        cell: ({ row: { original: device } }) => <DeviceNameCell device={device} />,
      }),
      columnHelper.accessor('realtime', {
        header: () => t('monitoring.alertStatus'),
        id: 'alertStatus',
        // eslint-disable-next-line react/no-unstable-nested-components -- accepted until we figure out a better way
        cell: (info) => (
          <div className="w-max-xxs flex flex__jc--center">
            <UnlessOffline realtimeArray={info.getValue()}>
              <UnitAlertState realtimeArray={info.getValue()} side="top" />
            </UnlessOffline>
          </div>
        ),
      }),
      columnHelper.accessor('realtime', {
        header: () => t('monitoring.doorOrLid'),
        id: 'doorLidStatus',
        // eslint-disable-next-line react/no-unstable-nested-components -- accepted until we figure out a better way
        cell: (info) => (
          <div className="w-max-xxs flex flex__jc--center">
            <UnlessOffline realtimeArray={info.getValue()}>
              <DoorOrLidStatusWrapper realtimeArray={info.getValue()} fallback="-" />
            </UnlessOffline>
          </div>
        ),
      }),
    ],
    [],
  );

  const handlePageSizeChange = (newPageSize: string) => {
    setPagination({ page: 1, pageSize: Number(newPageSize) });
  };

  const handlePageChange = (newPage: number) => {
    setPagination({ pageSize, page: newPage });
  };

  useDidMountEffect(() => {
    refetch();
  }, [page, pageSize, searchQuery]);

  const [searchParams] = useSearchParams();

  return (
    <>
      <Overview.Header>
        <h1 className="title">{t('monitoring.overview')}</h1>
        <SearchInput
          onSearchQueryChange={handleSearchQueryChange}
          placeholder={t('devices.searchPlaceholder')}
          id="monitoring-overview-search"
        />
      </Overview.Header>
      <Overview.Body>
        {(routeError || isError) && <LoadingErrorHint />}

        {!isLoading && !isError && (
          <>
            {data && (data?.data?.length ?? 0) > 0 && (
              <>
                <SortableTable<Device[], Device>
                  data={data.data || []}
                  columns={columns}
                  onRowClick={(device) => handleRowClick(device)}
                  isSelectedRow={(device) =>
                    isValueWithKeyInSearchParams(
                      'serialNumber',
                      device?.serialNumber,
                      searchParams,
                    )
                  }
                />
                {data.pagination && (
                  <Pagination
                    paginationData={data.pagination as PaginationResponse}
                    onPageChange={(newPage) => handlePageChange(newPage)}
                    onPageSizeChange={(newSize) => handlePageSizeChange(newSize)}
                    pageSizeOptions={[10, 25]}
                  />
                )}
              </>
            )}

            {data?.data?.length === 0 && !searchQuery && (
              <div className="flex flex__dir--column flex__ai--center bg-gray-50 p-xxxl">
                <Icon
                  name="not-paired"
                  size={IconSizeClasses.Large}
                  className="bg-gray-500"
                />
                <h1 className="font-weight-medium font-size-xl p-xl">
                  {t('monitoring.emptyState')}
                </h1>
                <p className="m-bottom-xxl">{t('monitoring.emptyStateHint')}</p>
                <Button
                  size="medium"
                  onClick={() => {
                    navigate('/devices');
                  }}
                >
                  {t('monitoring.gotoDeviceOverview')}
                </Button>
              </div>
            )}

            {data?.data?.length === 0 && searchQuery && (
              <div className="flex flex__dir--column flex__ai--center bg-gray-50 p-xxxl">
                <Icon
                  name="no-results"
                  size={IconSizeClasses.Large}
                  className="bg-gray-500"
                />
                <h1 className="font-weight-medium font-size-xl p-xl">
                  {t('devices.emptySearch')}
                </h1>
                <p className="m-bottom-xxl w-xl text-align-center">
                  {t('shared.tryAdjustSearchCriteria')}
                </p>
              </div>
            )}
          </>
        )}
      </Overview.Body>
    </>
  );
}
