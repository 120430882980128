import { Icons } from '@eppendorf/vnls-css/dist';
import { Button, Dialog, Icon, IconSizeClasses } from '@eppendorf/vnls-react-components';
import cn from 'classnames';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

export enum IncidentAcknowledgedScreens {
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE',
}

export type IncidentAcknowledgedDialogProps = {
  isOpen: boolean;
  onOpenChange: (value: boolean) => void;
  recipientCount: number;
  screen?: IncidentAcknowledgedScreens;
  onRetry?: () => void;
  acknowledgingIncident?: boolean;
};

export function IncidentAcknowledgedDialog({
  isOpen = false,
  onOpenChange,
  recipientCount,
  screen = IncidentAcknowledgedScreens.SUCCESS,
  onRetry,
  acknowledgingIncident,
}: IncidentAcknowledgedDialogProps): ReactElement {
  const { t } = useTranslation();

  const isOneRecipient = recipientCount === 1;

  const content: Record<
    IncidentAcknowledgedScreens,
    {
      title: string;
      icon: Icons;
      iconColor: string;
      actionText: string;
      description: ReactElement | string;
      actions: ReactElement;
    }
  > = {
    [IncidentAcknowledgedScreens.SUCCESS]: {
      title: t('incidentAcknowledgedDialog.titleSuccess'),
      icon: 'success',
      iconColor: 'bg-green-500',
      actionText: t('incidentAcknowledgedDialog.action'),
      description: isOneRecipient
        ? t('incidentAcknowledgedDialog.singleRecipientDescription')
        : t('incidentAcknowledgedDialog.multiRecipientsDescription'),
      actions: <Button>{t('shared.close')}</Button>,
    },
    [IncidentAcknowledgedScreens.FAILURE]: {
      title: t('incidentAcknowledgedDialog.titleFailure'),
      icon: 'failed',
      iconColor: 'bg-red-500',
      actionText: t('incidentAcknowledgedDialog.actionFailed'),
      description: (
        <>
          {t('incidentAcknowledgedDialog.descriptionFailed')}{' '}
          <a
            href="https://www.eppendorf.com/service-support/"
            className="btn-base btn btn--tertiary btn--info btn--small font-weight-regular"
            target="_blank"
            style={{ display: 'inline' }}
            rel="noreferrer"
          >
            {t('incidentAcknowledgedDialog.contactSupport')}
          </a>
        </>
      ),
      actions: (
        <>
          <Button onClick={() => onOpenChange(false)} variant="secondary">
            {t('shared.close')}
          </Button>
          <Button onClick={onRetry} disabled={acknowledgingIncident}>
            {t('shared.tryAgain')}
          </Button>
        </>
      ),
    },
  };

  const currentScreen = content[screen as IncidentAcknowledgedScreens];

  return (
    <Dialog isOpen={isOpen} onOpenChange={onOpenChange} className="w-max-4xl">
      <div className="flex flex__dir--column flex__ai--center text-align-center">
        <Icon
          name={currentScreen.icon}
          className={cn(currentScreen.iconColor, 'm-bottom-xxl')}
          size={IconSizeClasses.XLarge}
        />
        <Dialog.Title>{currentScreen.title}</Dialog.Title>
        <div>
          <p className="block m-bottom-l">{currentScreen.actionText}</p>
          <p>{currentScreen.description}</p>
        </div>
      </div>
      <Dialog.Actions>
        <Dialog.Close>{currentScreen.actions}</Dialog.Close>
      </Dialog.Actions>
    </Dialog>
  );
}
