import type { EnrichedNotificationRule } from '@eppendorf/vnls-audit-event-client';
import { Icon, IconSizeClasses } from '@eppendorf/vnls-react-components';

import { AffectedBadge } from '$features/audit-events/components/affected/affected-badge';

// remove the _archivedAt:<timestamp> suffix from the archived notification rules
const formatNotificationRuleName = (name?: string) =>
  name?.replace(/_archivedAt:\d+$/, '');

interface AffectedNotificationRuleEntityProps {
  entities: EnrichedNotificationRule[];
}

export function AffectedNotificationRuleEntity({
  entities,
}: AffectedNotificationRuleEntityProps) {
  if (!entities?.length) {
    return null;
  }

  const [firstNotificationRule, ...rest] = entities;

  return (
    <div className="flex flex__ai--center">
      <Icon
        name="notifications_unmasked"
        size={IconSizeClasses.Small}
        className="m-right-s"
      />
      <div className="whitespace-nowrap m-top-xs ellipsize">
        {formatNotificationRuleName(firstNotificationRule.name)}
      </div>
      <AffectedBadge value={rest.length} />
    </div>
  );
}
