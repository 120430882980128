import { Auth } from '@aws-amplify/auth';
import { ActivityCode, Audit, EntityType } from '@eppendorf/vnls-audit-event-client';

interface AuditEventOptions {
  isCurrentUserAffected?: boolean;
}

export const createAuditEvent = async (
  activityCode: ActivityCode,
  options: AuditEventOptions = { isCurrentUserAffected: false },
) => {
  const { isCurrentUserAffected } = options;
  const currentUser = (await Auth.currentSession()).getIdToken().payload;

  const auditEvent = Audit.triggered(activityCode)
    .byUser(currentUser?.email)
    .withTenantId(currentUser?.tenantId);

  if (isCurrentUserAffected) {
    auditEvent.withAffected({
      type: EntityType.User,
      id: currentUser?.email,
    });
  }

  return auditEvent;
};
