import { Dialog } from '@eppendorf/vnls-react-components';
import { t } from 'i18next';
import { Trans } from 'react-i18next';

import {
  ConfirmationDialog,
  ConfirmDialogIconType,
} from '$components/confirmation-dialog/confirmation-dialog';

export interface DeleteNotificationRuleSuccessDialogProps {
  name: string;
  isOpen: boolean;
  onOpenChange: (open: boolean) => void;
}

export function DeleteNotificationRuleSuccessDialog({
  name,
  isOpen,
  onOpenChange,
}: DeleteNotificationRuleSuccessDialogProps) {
  return (
    <Dialog
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      className="w-max-4xl"
      hideCloseButton={false}
    >
      <ConfirmationDialog
        title={t('notificationRules.deleteSuccessTitle')}
        dialogDescription={
          <Trans
            i18nKey="notificationRules.deleteSuccessDescription"
            values={{ name }}
            components={{ bold: <span className="font-weight-bold" /> }}
          />
        }
        iconType={ConfirmDialogIconType.success}
      />
    </Dialog>
  );
}
