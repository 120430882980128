import { Auth } from '@aws-amplify/auth';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

import { refreshAuthorizationHeader } from '$shared/auth/auth.utils';
import { LOCALHOST_BASE_URL } from '$shared/utils/api-paths';

import { environment } from '../../environment';

const configAxios: AxiosRequestConfig = {
  baseURL: environment.apiUrl || LOCALHOST_BASE_URL,
  withCredentials: true,
  timeout: 10 * 1000,
};

export const AxiosInstance = axios.create(configAxios);

export async function redirectToLogin(): Promise<void> {
  try {
    await Auth.federatedSignIn();
  } catch (federatedSignInError) {
    // eslint-disable-next-line no-console -- maybe useful for debugging
    console.error(federatedSignInError);
  }
}

async function errorHandling(error: unknown): Promise<never> {
  // eslint-disable-next-line no-console -- maybe useful for debugging
  console.error(error);
  await redirectToLogin();
  return Promise.reject(error);
}

AxiosInstance.interceptors.request.use(async (request) => {
  try {
    const authorizationHeader = await refreshAuthorizationHeader();
    request.headers.Authorization = authorizationHeader;
    return request;
  } catch (error) {
    return errorHandling(error);
  }
});

AxiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (!error.response || error.response.status !== 401) {
      return Promise.reject(error);
    }

    await Auth.signOut();
    return Promise.reject(error);
  },
);

export const fetcher = {
  async get<T>(url: string, config?: AxiosRequestConfig<T>): Promise<T> {
    const res = await AxiosInstance.get<T>(url, config);
    return res.data;
  },

  async post<T, R>(
    url: string,
    data: T,
    config?: AxiosRequestConfig<T>,
  ): Promise<AxiosResponse<R>> {
    return AxiosInstance.post<T, AxiosResponse<R>>(url, data, config);
  },

  async put<T, R>(url: string, data: T): Promise<R> {
    const res = await AxiosInstance.put<T, AxiosResponse<R>>(url, data);
    return res.data;
  },

  async patch<T, R>(url: string, data?: T): Promise<R> {
    const res = await AxiosInstance.patch<T, AxiosResponse<R>>(url, data);
    return res.data;
  },

  async delete<T>(url: string): Promise<AxiosResponse<T>> {
    return AxiosInstance.delete<T>(url, {
      headers: {
        'x-api-key': 'Easterly-nitrogen-involute-deride',
      },
    });
  },
};

export const fetchKeepalive = {
  async patch<T, R>(url: string, data: T): Promise<R> {
    const res = await fetch(`${environment.apiUrl}${url}`, {
      method: 'PATCH',
      keepalive: true,
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${AxiosInstance.defaults.headers.common.Authorization}`,
      },
    });

    return res.json();
  },
};
