import { useMutation } from '@tanstack/react-query';

import { fetcher } from '$shared/fetch';
import { MONITORING_BASE_PATH } from '$shared/utils/api-paths';

export interface MonitoringExportParameters {
  subject: string;
  name: string;
}

export interface MonitoringExportQueryParams {
  serialNumber: string;
  parameters: MonitoringExportParameters[] | [];
}

export interface EmailMonitoringExportQueryParams extends MonitoringExportQueryParams {
  startDate: string;
  endDate: string;
  exportPeriodName: string;
}

export const useEmailMonitoringExportWithQueryParams = (params: EmailMonitoringExportQueryParams) =>
  useMutation({
    mutationFn: () =>
      fetcher.post(`${MONITORING_BASE_PATH}/actions/email-export`, params),
  });
