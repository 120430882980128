import { Icon, IconSizeClasses } from '@eppendorf/vnls-react-components';
import cn from 'classnames';
import { t } from 'i18next';
import { useMemo } from 'react';

type EscalationLayerHeaderContentProps = {
  index: number;
  size?: 'small' | 'medium';
  isInfoOnly?: boolean;
};

type EscalationLayerLevels = 0 | 1 | 2;

export function EscalationLayerHeaderContent({
  index,
  size = 'medium',
  isInfoOnly,
}: EscalationLayerHeaderContentProps) {
  const iconColorByIndex: Record<
    EscalationLayerLevels,
    { iconBg: string; borderColor: string }
  > = {
    0: {
      iconBg: 'bg-blue-500',
      borderColor: 'border-color-blue-500',
    },
    1: {
      iconBg: 'bg-orange-500',
      borderColor: 'border-color-orange-500',
    },
    2: {
      iconBg: 'bg-red-500',
      borderColor: 'border-color-red-500',
    },
  };

  const iconConfig = iconColorByIndex[index as EscalationLayerLevels];

  const title = useMemo(() => {
    if (isInfoOnly) {
      return t('escalationLayer.recipients');
    }

    return t('escalationLayer.initialRecipients', { number: index + 1 });
  }, [index, isInfoOnly]);

  return (
    <div className={cn('flex flex__ai--center', size === 'small' ? 'p-y-s' : 'p-y-m')}>
      <div
        className={cn(
          'flex flex__ai--center flex__jc--center',
          iconConfig.borderColor,
          'bg-white border-solid border-s border-radius-xxl',
          size === 'small' ? 'icon--xs' : 'icon--s',
        )}
      >
        <Icon
          name="escalation"
          size={size === 'small' ? IconSizeClasses.XXSmall : IconSizeClasses.XSmall}
          className={iconConfig.iconBg}
        />
      </div>

      <p className="p-m">{title}</p>
    </div>
  );
}
