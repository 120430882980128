import type { EnrichedAuditEvent } from '@eppendorf/vnls-audit-event-client';
import { keepPreviousData, useInfiniteQuery } from '@tanstack/react-query';

import { fetcher } from '$shared/fetch';
import { createPathWithQueryParams, AUDIT_BASE_PATH } from '$shared/utils/api-paths';

export const auditEventsQueryKey = 'audit-events';

export const createAuditEventsQuery = (from: string | null, to: string | null) => ({
  queryKey: [auditEventsQueryKey, from, to],
  queryFn: ({ pageParam }: { pageParam: EnrichedAuditEvent | undefined }) =>
    fetcher.get<EnrichedAuditEvent[]>(
      createPathWithQueryParams(AUDIT_BASE_PATH, {
        pageSize: 10,
        ...(from !== undefined && { from }),
        ...(to !== undefined && { to }),
        ...(pageParam && {
          searchAfterTimestampIso: pageParam.timestamp,
        }),
      }),
    ),
  getNextPageParam: (lastPage: EnrichedAuditEvent[], _pages: EnrichedAuditEvent[][]) => {
    if (lastPage.length) {
      return lastPage.at(-1);
    }

    return undefined;
  },
  initialPageParam: undefined,
  staleTime: Infinity,
  placeholderData: keepPreviousData,
});

export const useAuditEventsQuery = (from: string | null, to: string | null) =>
  useInfiniteQuery(createAuditEventsQuery(from, to));
