import { parseISO } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';

import i18next from '$shared/i18n/i18n';

export function createCurrentDateString(): string {
  const date = new Date();
  return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
}

export function getLocalizedTime(UTCUnixTime: string | number): string {
  return i18next.t('translation:shared.intlDateTime', {
    val: new Date(UTCUnixTime),
    formatParams: {
      val: {
        hour: 'numeric',
        minute: 'numeric',
        hourCycle: 'h23',
      },
    },
  });
}

export function getLocalizedMonthAndDay(UTCUnixTime: string | number): string {
  return i18next.t('translation:shared.intlDateTime', {
    val: new Date(UTCUnixTime),
    formatParams: {
      val: {
        month: 'numeric',
        day: 'numeric',
      },
    },
  });
}

export function getLocalizedTimeWithSeconds(dateInput: string | number): string {
  const date = new Date(dateInput);

  return i18next.t('translation:shared.intlDateTime', {
    val: date,
    formatParams: {
      val: {
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        hourCycle: 'h23',
        timeZone: 'UTC', // Force UTC timezone
      },
    },
  });
}

export function getLocalizedDate(UTCUnixTime: string | number): string {
  return i18next.t('translation:shared.intlDateTime', {
    val: new Date(UTCUnixTime),
    formatParams: {
      val: {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      },
    },
  });
}

export function getLocalizedDateTime(UTCUnixTime: string | number): string {
  return i18next.t('translation:shared.intlDateTime', {
    val: new Date(UTCUnixTime),
    formatParams: {
      val: {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
      },
    },
  });
}

export function getFormattedLocalizedDate(UTCUnixTime: string | number): string {
  return formatInTimeZone(UTCUnixTime, 'UTC', 'dd.MM.yyyy, HH:mm');
}

export function getFormattedLocalizedDateTimeWithSeconds(
  UTCUnixTime: string | number,
): string {
  return formatInTimeZone(UTCUnixTime, 'UTC', 'dd.MM.yyyy, HH:mm:ss');
}

export function getFormattedLocalizedDateNoTime(dateInput: string | number): string {
  if (!dateInput) return '';

  // Handle unix timestamp (number in seconds or milliseconds)
  if (typeof dateInput === 'number') {
    // If timestamp is in seconds (Unix timestamp), convert to milliseconds
    const timestamp = dateInput < 10000000000 ? dateInput * 1000 : dateInput;
    return formatInTimeZone(timestamp, 'UTC', 'dd.MM.yyyy');
  }

  // Handle ISO string by parsing the date without timezone conversion
  return formatInTimeZone(parseISO(dateInput), 'UTC', 'dd.MM.yyyy');
}
