import { RecipientSubject } from '@eppendorf/vnls-notifications-utils';
import { Badge, Icon, IconSizeClasses } from '@eppendorf/vnls-react-components';
import cn from 'classnames';
import { t } from 'i18next';
import { useState } from 'react';

import { capitalize } from '$shared/utils/string.utils';

import { Card } from '$components/card';
import { EscalationLayerHeaderContent } from '$features/notifications/add-or-edit-notification-rule-dialog/screens/components/escalation-layer/escalation-layer-header-content';
import { renderEscalateAfter } from '$features/notifications/detail/utils';

type NotificationsDetailsEscalationLayerCardProps = {
  index: number;
  recipients: RecipientSubject[];
  escalateAfter: number;
};

export function NotificationsDetailsEscalationLayerCard({
  index,
  recipients,
  escalateAfter,
}: NotificationsDetailsEscalationLayerCardProps) {
  const [open, setOpen] = useState<boolean>(false);

  const handleToggle = () => setOpen((value) => !value);

  return (
    <Card className="notification-detail-escalation-layer__card">
      <div className="notification-detail-escalation-layer__tail--before" />
      <button
        type="button"
        onClick={handleToggle}
        className="notification-detail-escalation-layer__card-header flex flex__jc--space-between flex__ai--center p-x-l"
        data-testid={`escalation-toggle-${index}`}
      >
        <EscalationLayerHeaderContent index={index} size="small" />
        <Icon size={IconSizeClasses.XSmall} name={open ? 'down' : 'right'} />
      </button>
      {open && recipients?.length > 0 && (
        <div className="p-x-xl p-bottom-xl">
          {recipients?.map((recipient, i) => (
            <div
              key={recipient.id}
              className={cn(
                i !== recipients.length - 1 && 'm-bottom-xl',
                'flex gap-m flex__ai--center',
              )}
            >
              <p>{recipient.name}</p>
              {recipient.channels?.map((channel) => (
                <Badge key={channel} size="small" color="blue">
                  {capitalize(channel)}
                </Badge>
              ))}
            </div>
          ))}
        </div>
      )}

      <Card.Footer>
        {t('escalationLayer.actionAfter')} {renderEscalateAfter(escalateAfter)}
      </Card.Footer>

      <div className="notification-detail-escalation-layer__tail--after" />
    </Card>
  );
}
