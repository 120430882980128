import cn from 'classnames';
import { ReactNode } from 'react';

import './card.scss';

type CardFooterProps = {
  children: ReactNode;
  variant?: 'default' | 'success';
  spacingY?: 'small' | 'large';
};

export function CardFooter({
  children,
  variant = 'default',
  spacingY = 'small',
}: CardFooterProps) {
  return (
    <div
      className={cn(
        'card-internal__footer w-full flex__jc--center p-x-l  border-solid border-top-s',
        spacingY === 'large' ? 'p-y-l' : 'p-y-s',
        {
          'bg-gray-50 border-color-gray-500': variant === 'default',
          'bg-green-500 color-white border-color-green-500': variant === 'success',
        },
      )}
    >
      {children}
    </div>
  );
}
