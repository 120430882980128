import { Device as InventoryDevice } from '@eppendorf/vnls-inventory-service-types';
import type { DeviceDTO } from '@eppendorf/vnls-notifications-utils';
import { Icon, IconSizeClasses } from '@eppendorf/vnls-react-components';
import { Device } from '@eppendorf/vnls-telemetry-and-events-types';
import { ReactElement } from 'react';

import { normalizeDevice } from '$shared/utils/normalize-device.utils';

import { useDeviceType } from '$components/app-config/useDeviceType';
import { DeviceNameOrSerialNumber } from '$features/device-list/device-name-or-serialnumber';
import { searchDeviceIcon } from '$features/devices/devices.utils';

export function DeviceNameCell({
  device,
  isIconUnmasked,
}: {
  device: Device | InventoryDevice | DeviceDTO | null;
  isIconUnmasked?: boolean;
}): ReactElement {
  const normalizedDevice = normalizeDevice(device);
  const deviceType = useDeviceType(normalizedDevice);

  return (
    <div className="flex flex__ai--center">
      <div className="w-3xs h-3xs flex flex__jc--center flex__ai--center">
        <Icon
          size={IconSizeClasses.Small}
          name={searchDeviceIcon(deviceType as string, isIconUnmasked)}
        />
      </div>

      <div className="m-left-s">
        <h2 className="font-weight-bold">
          <DeviceNameOrSerialNumber device={normalizedDevice || undefined} />
        </h2>
        <div className="color-gray-800">
          {normalizedDevice?.manufacturer} {normalizedDevice?.model}
        </div>
      </div>
    </div>
  );
}
