import { Dialog } from '@eppendorf/vnls-react-components';
import { useTranslation } from 'react-i18next';

import { ErrorDialogRetry } from '$components/error-dialog-retry/error-dialog-retry';

export interface DeleteNotificationRuleErrorDialogProps {
  isOpen: boolean;
  onOpenChange: (open: boolean) => void;
  onTryAgain: () => void;
}

export function DeleteNotificationRuleErrorDialog({
  isOpen,
  onOpenChange,
  onTryAgain,
}: DeleteNotificationRuleErrorDialogProps) {
  const { t } = useTranslation();
  return (
    <Dialog
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      className="w-max-4xl"
      hideCloseButton={false}
    >
      <ErrorDialogRetry
        onTryAgain={onTryAgain}
        title={t('errors.errorOccurred')}
        message={t('errors.unexpected')}
      />
    </Dialog>
  );
}
