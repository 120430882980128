import { Device as InventoryDevice } from '@eppendorf/vnls-inventory-service-types';
import { DeviceDTO } from '@eppendorf/vnls-notifications-utils';
import { Device } from '@eppendorf/vnls-telemetry-and-events-types';

// There is a mismatch between DeviceDTO and Device (InventoryDevice) properties
// we can't fix it in Yggdrasil since the mobile app is using the DeviceDTO, any change will break the mobile app
// so we need to normalize the DeviceDTO to InventoryDevice here
// TODO: once we are able to change the mobile app
//  we should remove this function and update DeviceDTO to match Device (InventoryDevice)
export function normalizeDevice(
  device: Device | InventoryDevice | DeviceDTO | null,
): InventoryDevice | null {
  if (device === null) {
    return null;
  }

  const baseDevice: Partial<Device> = {
    manufacturer: device.manufacturer,
    serialNumber: device.serialNumber,
    name: (() => {
      if ('deviceName' in device) return device.deviceName;
      if ('name' in device) return device.name;
      return undefined;
    })(),
    type: (() => {
      if ('deviceType' in device) return device.deviceType;
      if ('type' in device) return device.type;
      return undefined;
    })(),
    model: (() => {
      if ('deviceModel' in device) return device.deviceModel;
      if ('model' in device) return device.model;
      return undefined;
    })(),
  };

  return baseDevice as InventoryDevice;
}
