import { NotificationRule } from '@eppendorf/vnls-notifications-utils';
import { Badge, HasClassName } from '@eppendorf/vnls-react-components';
import i18next from 'i18next';
import { FunctionComponentElement } from 'react';

export interface NotificationRuleDetailHeaderProps extends HasClassName {
  notificationRule: NotificationRule;
  showNotificationRuleStatus?: boolean;
}

export function NotificationDetailHeader({
  className,
  notificationRule,
  showNotificationRuleStatus = true,
}: NotificationRuleDetailHeaderProps): FunctionComponentElement<NotificationRuleDetailHeaderProps> {
  const badgeColor = () => (
    <Badge color="green" bordered>
      {i18next.t('translation:notificationRules.active')}
    </Badge>
  );
  const statusBadge = showNotificationRuleStatus ? badgeColor() : null;

  return (
    <div className={`flex flex__ai--center ${className}`}>
      <div className="m-left-s m-right-l">
        <h2 className="title">{notificationRule.name}</h2>
      </div>
      <div className="m-right-l">{statusBadge}</div>
    </div>
  );
}
