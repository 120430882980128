import { NotificationMessageDTO } from '@eppendorf/vnls-notifications-utils';
import { Icon, IconSizeClasses } from '@eppendorf/vnls-react-components';

import { capitalize } from '$shared/utils/string.utils';

import { getEventIconName } from '$features/monitoring/events/events.utils';

type IncidentDetailMessagesContentProps = {
  notificationMessage: NotificationMessageDTO;
};

export function IncidentDetailMessagesContent({
  notificationMessage,
}: IncidentDetailMessagesContentProps) {
  return (
    <>
      <Icon
        className="m-right-s"
        name={`event-${getEventIconName(notificationMessage?.level || '')}`}
        size={IconSizeClasses.XSmall}
      />
      <span>
        <span className="body-bold">{capitalize(notificationMessage?.level || '')}</span>
        {': '}
        {notificationMessage.message}
      </span>
    </>
  );
}
