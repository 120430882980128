type IncidentUnreadIndicatorProps = {
  isRead: boolean;
};

export function IncidentUnreadIndicator({ isRead }: IncidentUnreadIndicatorProps) {
  return !isRead ? (
    <div
      className="bg-blue-500 border-radius-xxl m-bottom-m"
      data-testid="incident-unread-indicator"
      style={{ height: '8px', width: '8px', top: '6px', left: '0' }}
    />
  ) : null;
}
