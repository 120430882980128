import { DateRangePicker } from '@eppendorf/vnls-react-components';
import { hasPermission } from '@eppendorf/vnls-user-tenant-utils';
import { parseISO, subDays, subMonths } from 'date-fns';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, useSearchParams } from 'react-router-dom';

import { useTokenAttributes } from '$shared/custom-hooks/useTokenAttributes';
import { isFeatureEnabled } from '$shared/feature-toggle/is-feature-enabled';

import { Overview } from '$components/overview-box/overview-box';
import { AuditEventsList } from '$features/audit-events/audit-events-list';
import { useDateRangeChange } from '$features/monitoring/history-charts/useDateRangeChange';

export function AuditEventsOverview(): ReactElement {
  const { t } = useTranslation();
  const isEnabled = isFeatureEnabled('showAuditEvents');
  const [searchParams, setSearchParams] = useSearchParams();
  const to = searchParams.get('to');
  const from = searchParams.get('from');
  const { onRangeChange } = useDateRangeChange(true);
  const disabledDates = {
    before: subDays(new Date(), 89),
    after: new Date(),
  };

  /**
   * We're using useTokenAttributes and hasPermission directly instead of calling useAccessControlGuard
   * because userPermissions is set to false as an intial state which will redirect the user to the devices page
   * without waiting for the hasPermission check to complete.
   */
  const { permissions: userPermissions } = useTokenAttributes(['permissions']);
  const isAllowed = hasPermission('VIEW_AUDIT_TRAIL_OVERVIEW', userPermissions);
  const hasPermissionToViewAuditTrailOverView =
    userPermissions === undefined ? true : isAllowed;

  if (!isEnabled || !hasPermissionToViewAuditTrailOverView) {
    return <Navigate to="/devices" replace />;
  }

  return (
    <Overview>
      <Overview.Header>
        <h1 className="title">{t('auditEvents.overview')}</h1>
        <div style={{ width: '240rem' }}>
          <DateRangePicker
            dateRange={{
              from: from ? parseISO(from) : undefined,
              to: to ? parseISO(to) : undefined,
            }}
            onDateRangeChange={onRangeChange}
            autoFocus
            defaultMonth={subMonths(disabledDates.after, 1)}
            disabled={disabledDates}
            startMonth={disabledDates.before}
            endMonth={disabledDates.after}
            className="m-bottom-m"
          />
        </div>
      </Overview.Header>

      <Overview.Body>
        <AuditEventsList from={from} to={to} setSearchParams={setSearchParams} />
      </Overview.Body>
    </Overview>
  );
}
