import { UnitState } from '@eppendorf/vnls-telemetry-and-events-types';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { useRealtimeDevice } from '$features/monitoring/monitoring.graphql.api';
import { findBySubjectAndComponent } from '$features/monitoring/monitoring.utils';
import { MonitoringParameterSimple } from '$features/monitoring/parameters/monitoring-parameter-simple';
import { MonitoringParameterWrapper } from '$features/monitoring/parameters/monitoring-parameter-wrapper';
import { useDeviceTelemetryData } from '$features/monitoring/parameters/useDeviceTelemetryData';

export function MonitoringParameters() {
  const [searchParams] = useSearchParams();
  const monitorSerialNumber = searchParams.get('serialNumber')!;
  const { data: device, invalidate } = useRealtimeDevice(
    searchParams.get('manufacturer')!,
    monitorSerialNumber,
  );

  const { t } = useTranslation();
  const { primaryParameters, secondaryParameters } = useDeviceTelemetryData(device);

  const online =
    findBySubjectAndComponent(device?.realtime, 'Unit', 'unitState')?.value !==
    UnitState.Offline.toString();

  return (
    <div className="monitoring-parameters m-top-xl overflow-hidden overflow-y-auto">
      {primaryParameters.map((telemetry) => (
        <MonitoringParameterWrapper
          key={telemetry.subject}
          telemetry={telemetry}
          device={device!}
          invalidateDeviceQuery={invalidate}
        />
      ))}

      {Boolean(secondaryParameters.length) && (
        <div className="box bg-gray-50" role="button" tabIndex={0}>
          <div
            className={cn({ 'flex flex__jc--center': secondaryParameters.length > 1 })}
          >
            {secondaryParameters.map((tele, index) => (
              <MonitoringParameterSimple
                key={tele.subject}
                name={tele.name}
                value={online ? tele.value : t('monitoringDetail.noLiveData')}
                timestamp={online ? tele.timestamp : 0}
                className={cn({
                  'border-solid border-color-gray-300 border-left-s': index,
                  'p-x-xxl': index || secondaryParameters.length > 1,
                })}
                fullWidth={secondaryParameters.length === 1}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
}
