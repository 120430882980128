import type { RecipientSubject } from '@eppendorf/vnls-notifications-utils';
import {
  Button,
  Dialog,
  DotsLoader,
  useDidMountEffect,
} from '@eppendorf/vnls-react-components';
import {
  User,
  UserStatus,
  Pagination as PaginationType,
} from '@eppendorf/vnls-user-tenant-utils';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  defaultEscalateAfterValue,
  initialPageIndex,
  initialPageSize,
} from '$features/notifications/add-or-edit-notification-rule-dialog/constants';
import { EscalationLayer } from '$features/notifications/add-or-edit-notification-rule-dialog/screens/components/escalation-layer/escalation-layer';
import {
  AddNotificationRuleScreenProps,
  NotificationRuleEscalationLayerMode,
} from '$features/notifications/types';
import { useUsersWithQueryParams } from '$features/users/users.api';

import './recipients-screen.scss';

interface RecipientsScreenProps extends AddNotificationRuleScreenProps {
  onBack: () => void;
  onContinue: () => void;
  screenHasErrors: boolean;
  isLoading: boolean;
}

export function RecipientsScreen({
  formControl,
  onBack,
  onContinue,
  screenHasErrors,
  isLoading,
}: RecipientsScreenProps) {
  const { t } = useTranslation();
  const modalRef = useRef<HTMLDivElement | null>(null);
  const { register, watch, getValues, setValue } = formControl;
  const tags = getValues('tags') ?? [];
  const isInfoOnly = tags.length === 1 && tags.includes('info');

  const [{ page, pageSize }] = useState<PaginationType>({
    page: initialPageIndex,
    pageSize: initialPageSize,
  });

  const {
    data: getUsersData,
    isLoading: getUsersLoading,
    refetch: refetchGetUsers,
  } = useUsersWithQueryParams({ page, pageSize, status: UserStatus.Active });

  useDidMountEffect(() => {
    refetchGetUsers();
  }, [page, pageSize]);

  useEffect(() => {
    modalRef.current = document.querySelector('[role="dialog"]');
  }, []);

  const escalationLayers = watch('escalationLayers');

  useEffect(() => {
    if (isInfoOnly) {
      const escalationLayersValues = getValues('escalationLayers') || [];
      const updatedEscalationLayers = escalationLayersValues
        .slice(0, 1)
        .map((escalationLayer) => ({
          ...escalationLayer,
          mode: NotificationRuleEscalationLayerMode.STOP,
        }));

      setValue('escalationLayers', updatedEscalationLayers);
    }
  }, [setValue, isInfoOnly]);

  useEffect(() => {
    register('escalationLayers', {
      validate: (layers) => layers.some((layer) => layer.recipients.length > 0),
    });
  }, [register]);

  const handleRecipientClick = (layerIndex: number, user: User) => {
    const escalationLayersValues = getValues('escalationLayers') || [];

    const recipient: RecipientSubject = {
      id: user.id,
      name: `${user.email}`,
      channels: ['email'],
      type: 'user',
    };

    // Find the first layer where this user is already selected
    const firstSelectedLayerIndex = escalationLayersValues.findIndex((layer) =>
      layer.recipients.some((u) => u.id === recipient.id),
    );

    let updatedLayers = escalationLayersValues.map((layer, index) => {
      if (index === layerIndex) {
        // Toggle recipient selection in the clicked layer
        const isAlreadySelected = layer.recipients.some((u) => u.id === recipient.id);
        return {
          ...layer,
          recipients: isAlreadySelected
            ? layer.recipients.filter((u) => u.id !== recipient.id) // Remove recipient
            : [...layer.recipients, recipient], // Add recipient
        };
      }
      return layer;
    });

    // If the user was in another layer, remove them from all other layers
    if (firstSelectedLayerIndex !== -1 && firstSelectedLayerIndex !== layerIndex) {
      updatedLayers = updatedLayers.map((layer) => ({
        ...layer,
        recipients: layer.recipients.filter((u) => u.id !== recipient.id),
      }));
    }

    setValue('escalationLayers', updatedLayers, {
      shouldValidate: true,
      shouldDirty: true,
    });
  };

  function addEscalationLayer(): void {
    const layers = getValues('escalationLayers');

    if (layers.length < 3) {
      setValue(
        'escalationLayers',
        [
          ...layers,
          {
            recipients: [],
            escalateAfter: defaultEscalateAfterValue,
            mode: NotificationRuleEscalationLayerMode.STOP,
          },
        ],
        {
          shouldValidate: true,
          shouldDirty: true,
        },
      );
    }
  }

  const handleEscalateAfterChange = (layerIndex: number, value: string) => {
    const updatedLayers = getValues('escalationLayers').map((layer, index) =>
      index === layerIndex ? { ...layer, escalateAfter: Number(value) } : layer,
    );

    setValue('escalationLayers', updatedLayers, {
      shouldValidate: true,
      shouldDirty: true,
    });
  };

  const handleToggleMode = (
    layerIndex: number,
    mode: NotificationRuleEscalationLayerMode,
  ) => {
    const escalationLayersValues = getValues('escalationLayers') || [];

    if (mode === NotificationRuleEscalationLayerMode.STOP) {
      // If only one layer exists, just update its mode to "stop"
      if (escalationLayersValues.length === 1) {
        setValue(
          'escalationLayers',
          escalationLayersValues.map((layer, index) =>
            index === layerIndex ? { ...layer, mode } : layer,
          ),
          { shouldValidate: true, shouldDirty: true },
        );
        return;
      }

      // If stopping in a multi-layer setup, remove all layers after the clicked one
      const updatedLayers = escalationLayersValues
        .slice(0, layerIndex + 1)
        .map((layer, index) => (index === layerIndex ? { ...layer, mode } : layer));

      setValue('escalationLayers', updatedLayers, {
        shouldValidate: true,
        shouldDirty: true,
      });
      return;
    }

    // If mode is "escalate", update mode and add a new layer if last layer is escalated
    const updatedLayers = escalationLayersValues.map((layer, index) =>
      index === layerIndex ? { ...layer, mode } : layer,
    );

    setValue('escalationLayers', updatedLayers, {
      shouldValidate: true,
      shouldDirty: true,
    });

    // If escalating the last layer and there's room, add another
    if (
      mode === NotificationRuleEscalationLayerMode.ESCALATE &&
      layerIndex === updatedLayers.length - 1 &&
      updatedLayers.length < 3
    ) {
      addEscalationLayer();
    }

    if (modalRef.current) {
      setTimeout(() => {
        modalRef.current?.scrollTo({
          top: modalRef.current.scrollHeight,
          behavior: 'smooth',
        });
      }, 500);
    }
  };

  function getFilteredData(layerIndex: number) {
    const currentEscalationLayers = getValues('escalationLayers') || [];

    return (
      getUsersData?.data.filter((user) => {
        // Find the first layer in which the user was selected
        const firstSelectedLayerIndex = currentEscalationLayers.findIndex((layer) =>
          layer.recipients.some((u) => u.id === user.id),
        );

        // If the user is already selected in a previous layer, filter them out
        return firstSelectedLayerIndex === -1 || firstSelectedLayerIndex === layerIndex;
      }) || []
    );
  }

  return (
    <div className="recipients-screen">
      {getUsersLoading && <DotsLoader className="m-top-10xl m-bottom-10xl" />}

      {getUsersData && !getUsersLoading && (
        <>
          <p className="m-bottom-xxxl">{t('notificationRules.selectRecipients')}</p>

          {escalationLayers.map((layer, index) => (
            <EscalationLayer
              key={`card-${index.toString()}`}
              index={index}
              data={getFilteredData(index)}
              onRowClick={handleRecipientClick}
              onToggleMode={handleToggleMode}
              layer={layer}
              onEscalateAfterChange={handleEscalateAfterChange}
              escalationLayers={escalationLayers}
              isInfoOnly={isInfoOnly}
            />
          ))}

          <Dialog.Actions>
            <Button variant="secondary" onClick={onBack}>
              {t('shared.back')}
            </Button>

            <Button
              onClick={onContinue}
              disabled={
                isLoading ||
                screenHasErrors ||
                escalationLayers.some((layer) => layer.recipients.length === 0)
              }
            >
              {t('shared.continue')}
            </Button>
          </Dialog.Actions>
        </>
      )}
    </div>
  );
}
