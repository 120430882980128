import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { AuthGuard } from '$shared/auth/auth-guard';

import { CookieInfoButton } from '$components/cookie-policy';
import { FooterLink } from '$components/footer/footer-link';

export function Footer(): ReactElement {
  const { t } = useTranslation();

  return (
    <footer className="flex flex-wrap flex__jc--center m-bottom-xl">
      <FooterLink text={t('footer.usepolicy')} navigationPath="/usepolicy.html" />

      <FooterLink text={t('footer.termsAndConditions')} navigationPath="/terms.html" />

      <FooterLink text={t('footer.privacyPolicy')} navigationPath="/privacypolicy.html" />

      <FooterLink
        text={t('footer.imprint')}
        navigationPath="https://corporate.eppendorf.com/en/imprint/"
      />

      <FooterLink
        text={t('footer.oslibraries')}
        target="_self"
        navigationPath="/oslibraries"
      />

      <AuthGuard>
        <CookieInfoButton />
      </AuthGuard>

      <FooterLink
        text={t('footer.about')}
        navigationPath="https://www.eppendorf.com/en/visionize-lab-suite/"
      />

      <FooterLink
        text={t('footer.support')}
        navigationPath="https://forms.office.com/pages/responsepage.aspx?id=UAHVMFqa20O4Cf-Q16tP1lP5BAS6QABPppTpV2u_nB5UQlZLTTVYTVZVRU9IVTNVUEtFN0hYN0NCQS4u&route=shorturl"
      />
    </footer>
  );
}
