import { Icons } from '@eppendorf/vnls-css/dist';
import { Device } from '@eppendorf/vnls-inventory-service-types';
import i18next from 'i18next';

import { getCountryName, isValidISOCountry } from '$shared/utils/country.utils';

export function getConnectionStatusText(
  connectionStatus: Device['connectionStatus'],
): string {
  switch (connectionStatus) {
    case 'pending':
    case 'readyToPair':
      return i18next.t('translation:deviceConnectionStatus.pending');
    case 'paired':
      return i18next.t('translation:deviceConnectionStatus.paired');
    case 'waitingForMetadata':
      return i18next.t('translation:deviceConnectionStatus.waitingForMetadata');
    default:
      return '';
  }
}

// Temporary solution, until this comes from the browser lib
const deviceIcons: Set<Icons> = new Set([
  'centrifuge-multi-purpose',
  'centrifuge-large',
  'centrifuge',
  'electronic-pipette',
  'ep-motion',
  'freezer',
  'generic',
  'incubator',
  'incubator-c170i',
  'incubator-galaxy-170',
  'mixing-heating',
  'pipette',
  'scivario-twin',
  'sensor-multi-mobile-attachable',
  'shaker-open-air',
  'shaker-s41i',
  'shaker-s44i',
  'shaker',
  'small-bioreactor',
  'spectrometer',
  'thermocycler',
  'thermomixer',
  'sense-monitor',
  'sense-sensor',
  'sense-gateway',
  'vn-box',
]);

export function searchDeviceIcon(deviceType: string | undefined, isUnmasked: boolean = false): Icons {
  if (!deviceType) {
    return 'generic';
  }

  const unmaskedIcon = `${deviceType}_unmasked` as Icons;
  const maskedIcon = deviceType as Icons;
  const senseIcon = `sense-${deviceType}` as Icons;

  if (deviceIcons.has(maskedIcon)) {
    return isUnmasked ? unmaskedIcon : maskedIcon;
  }

  if (deviceIcons.has(senseIcon)) {
    return senseIcon;
  }

  return 'generic';
}


export const resolveDeviceMetaDataValue = (value: string | undefined) =>
  value && value.length ? value : undefined;

export const resolveDeviceMetaCountryValue = (
  value: string | undefined,
  notFoundMessage: string,
) => {
  if (value?.length && isValidISOCountry(value)) {
    return getCountryName(value)?.label;
  }

  if (value?.length) {
    return notFoundMessage;
  }

  return undefined;
};
