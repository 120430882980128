import type { EnrichedDevice } from '@eppendorf/vnls-audit-event-client';
import type { Icons } from '@eppendorf/vnls-css/dist';
import { Icon, IconSizeClasses } from '@eppendorf/vnls-react-components';

import { AffectedBadge } from '$features/audit-events/components/affected/affected-badge';

interface AffectedDeviceEntityProps {
  entities: EnrichedDevice[];
  deviceType?: string;
}

export function AffectedDeviceEntity({
  entities,
  deviceType = 'generic',
}: AffectedDeviceEntityProps) {
  if (!entities?.length) {
    return null;
  }

  const [firstDevice, ...rest] = entities;

  return (
    <div className="flex flex__ai--center">
      <Icon
        name={`${deviceType}_unmasked` as Icons}
        size={IconSizeClasses.Small}
        className="m-right-s"
      />
      <div className="whitespace-nowrap ellipsize">
        {firstDevice.name ?? firstDevice.serialNumber}
      </div>
      <AffectedBadge value={rest.length} />
    </div>
  );
}
