import { Icons } from '@eppendorf/vnls-css/dist';
import { Icon, IconSizeClasses } from '@eppendorf/vnls-react-components';
import { useQueries } from '@tanstack/react-query';
import { createColumnHelper } from '@tanstack/react-table';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { DeviceNameCell } from '$components/device/name-cell';
import { AssociatedDevicesTable } from '$features/devices/associated-devices-table/associated-devices-table';
import { createDeviceQuery, useGetDevice } from '$features/devices/devices.api';
import { AssociatedDevicesModel } from '$features/devices/devices.types';

export function BoxAssociatedDevices() {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();

  const [columnsData, setColumnsData] = useState<AssociatedDevicesModel[]>([]);

  const { data, isLoading: isBoxDeviceLoading } = useGetDevice({
    manufacturer: searchParams.get('manufacturer') ?? '',
    serialNumber: searchParams.get('serialNumber') ?? '',
  });

  const queryResults = useQueries({
    queries:
      data?.connectedDevices?.map((serialNumber: string) =>
        createDeviceQuery({
          manufacturer: 'Eppendorf',
          serialNumber,
          enabled: !isBoxDeviceLoading,
        }),
      ) || [],
  });

  const allQueriesCompleted =
    !isBoxDeviceLoading &&
    queryResults.length === (data?.connectedDevices?.length ?? 0) &&
    queryResults.every((result) => result.isSuccess || result.isError);

  /* eslint-disable security/detect-object-injection -- Improve in future */
  useEffect(() => {
    if (allQueriesCompleted) {
      const mappedData: AssociatedDevicesModel[] = [];

      for (let i = 0; i < queryResults.length; i += 1) {
        const device: AssociatedDevicesModel = {
          ...queryResults[i].data,
          serialNumber:
            queryResults[i].data?.serialNumber ?? data?.connectedDevices?.[i] ?? '',
          manufacturer: queryResults[i].data?.manufacturer ?? 'Eppendorf',
          name: queryResults[i].data?.name ?? '',
          // TODO: is not implemented yet
          connectionType: 'wired',
        };
        mappedData.push(device);
      }

      setColumnsData(mappedData.sort((a, b) => a.name.localeCompare(b.name)));
    }
  }, [allQueriesCompleted]);
  /* eslint-enable security/detect-object-injection */

  const columnHelper = createColumnHelper<AssociatedDevicesModel>();

  const columns = useMemo(
    () => [
      columnHelper.accessor('connectionType', {
        header: () => t('deviceDetail.boxConnectedDevicesTable.connection'),
        // eslint-disable-next-line react/no-unstable-nested-components -- accepted until we figure out a better way
        cell: ({ row: { original: connectedDevice } }) => (
          <div className="flex__ai--center flex__jc--space-between">
            <div className="w-3xs h-3xs flex flex__jc--center flex__ai--center">
              <Icon
                size={IconSizeClasses.Small}
                name={connectedDevice.connectionType as Icons}
              />
            </div>
          </div>
        ),
      }),
      columnHelper.accessor('serialNumber', {
        header: () => t('deviceDetail.boxConnectedDevicesTable.devices'),
        // eslint-disable-next-line react/no-unstable-nested-components -- accepted until we figure out a better way
        cell: ({ row: { original: associatedDevice } }) => (
          <DeviceNameCell device={associatedDevice} />
        ),
      }),
    ],
    [columnsData],
  );

  return (
    <AssociatedDevicesTable<AssociatedDevicesModel>
      loading={isBoxDeviceLoading || !allQueriesCompleted}
      columns={columns}
      data={columnsData}
      emptyMessage={t('deviceDetail.boxConnectedDevicesTable.emptyMessage')}
    />
  );
}
