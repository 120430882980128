import { ReactElement } from 'react';

import { Overview } from '$components/overview-box/overview-box';
import { MonitoringList } from '$features/monitoring/monitoring-list';

export function MonitoringOverview(): ReactElement {
  return (
    <Overview>
      <MonitoringList />
    </Overview>
  );
}
