import './confirm-delete-noti-rule-dialog.scss';
import { t } from 'i18next';

import { ConfirmDeleteDialog } from '$components/confirm-delete-dialog/confirm-delete-dialog';

export interface ConfirmDeleteNotiRuleDialogProps {
  isOpen?: boolean;
  onClose: () => void;
  onConfirmDelete: () => Promise<void>;
  name: string;
}

function Description() {
  return (
    <div className="text-align-left confirm-delete-noti-rule-description">
      <p>{t('notificationRules.confirmDeleteDescription.top')}</p>
      <ul>
        <li>{t('notificationRules.confirmDeleteDescription.bullet1')}</li>
        <li>{t('notificationRules.confirmDeleteDescription.bullet2')}</li>
        <li>{t('notificationRules.confirmDeleteDescription.bullet3')}</li>
      </ul>
    </div>
  );
}

export function ConfirmDeleteNotiRuleDialog({
  isOpen,
  onClose,
  onConfirmDelete,
  name,
}: ConfirmDeleteNotiRuleDialogProps) {
  return (
    <ConfirmDeleteDialog
      isOpen={isOpen}
      onClose={onClose}
      onConfirmDelete={onConfirmDelete}
      title={t('notificationRules.confirmDeleteTitle', { name })}
      description={<Description />}
    />
  );
}
