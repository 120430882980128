import type { EnrichedEntity } from '@eppendorf/vnls-audit-event-client';
import { Icon, IconSizeClasses } from '@eppendorf/vnls-react-components';

import { AffectedBadge } from '$features/audit-events/components/affected/affected-badge';

interface AffectedPlatformEntityProps {
  entities: EnrichedEntity[];
}

export function AffectedPlatformEntity({ entities }: AffectedPlatformEntityProps) {
  if (!entities?.length) {
    return null;
  }

  const [first, ...rest] = entities;

  return (
    <div className="flex flex__ai--center">
      <Icon
        name="maintenance_unmasked"
        size={IconSizeClasses.Small}
        className="m-right-s"
      />
      <div>{first.type}</div>
      <AffectedBadge value={rest.length} />
    </div>
  );
}
