import type { Device } from '@eppendorf/vnls-inventory-service-types';
import { Badge } from '@eppendorf/vnls-react-components';
import cn from 'classnames';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { capitalize } from '$shared/utils/string.utils';

import { useDeviceType } from '$components/app-config/useDeviceType';
import { useDeviceTypeConfig } from '$components/app-config/useDeviceTypeConfig';
import { EditInfoItem } from '$features/devices/device-information/edit-info-item';
import { InfoItem } from '$features/devices/device-information/info-item';
import { UpdateDeviceDialog } from '$features/devices/device-information/update-dialogs/update-device-dialog';
import { UpdateGatewayDialog } from '$features/devices/device-information/update-dialogs/update-gateway-dialog';
import {
  useGetDeviceUpdateStatusWithQueryParams,
  useGetGatewayUpdateStatus,
} from '$features/devices/devices.api';
import type { DeviceUpdateStatus } from '$features/devices/devices.types';

import styles from '../device-information.module.scss';

export function GeneralInformation({
  data,
  editMode,
}: {
  data: Device | undefined;
  editMode: boolean;
}) {
  const { t } = useTranslation();
  const deviceTypeConfig = useDeviceTypeConfig(data);
  const deviceType = capitalize(
    useDeviceType(data) || t('deviceDetail.fallbackHeadlineNameEmpty'),
  );

  const [isUpdateDeviceDialogOpen, setIsUpdateDeviceDialogOpen] = useState(false);
  const { data: updateDeviceStatus } =
    useGetDeviceUpdateStatusWithQueryParams<DeviceUpdateStatus>({
      deviceTypeId: data?.type ?? '',
      enabled: !!deviceTypeConfig?.supportOtaUpdate && !data?.model?.includes('gateway'),
    });

  const [isUpdateGatewayDialogOpen, setIsUpdateGatewayDialogOpen] = useState(false);
  // TODO: merge sense and other device types if ota update works correctly
  const { data: updateGatewayStatus } = useGetGatewayUpdateStatus<DeviceUpdateStatus>({
    enabled: data?.model?.includes('gateway'),
  });

  const getSoftwareVersion = () => (
    <>
      {data?.softwareRevision}
      {((updateGatewayStatus?.isUpdateAvailable && !!data?.model?.includes('gateway')) ||
        updateDeviceStatus?.isUpdateAvailable) && (
        <div
          className={cn(styles.updateBadge, 'm-left-m')}
          onClick={() => {
            setIsUpdateDeviceDialogOpen(!!updateDeviceStatus?.isUpdateAvailable);
            setIsUpdateGatewayDialogOpen(
              !!data?.model?.includes('gateway') &&
                !!updateGatewayStatus?.isUpdateAvailable,
            );
          }}
          role="presentation"
        >
          <Badge bordered color="blue" size="small">
            {t('deviceDetail.deviceInformation.updateAvailable')}
          </Badge>
        </div>
      )}
    </>
  );
  return (
    <>
      <div className="sub-title m-y-xxl">
        {t('deviceDetail.deviceInformation.generalInformation')}
      </div>
      <InfoItem
        label={t('deviceDetail.deviceInformation.deviceType')}
        value={deviceType}
      />
      {!editMode && (
        <InfoItem
          label={t('deviceDetail.deviceInformation.deviceName')}
          value={data?.name}
        />
      )}
      {editMode && (
        <EditInfoItem id="name" label={t('deviceDetail.deviceInformation.deviceName')} />
      )}
      <InfoItem
        label={t('deviceDetail.deviceInformation.serialNumber')}
        value={data?.serialNumber}
      />
      {!editMode && (
        <InfoItem
          label={t('deviceDetail.deviceInformation.customInventoryId')}
          value={data?.registrationNumber}
        />
      )}
      {editMode && (
        <EditInfoItem
          id="registrationNumber"
          label={t('deviceDetail.deviceInformation.customInventoryId')}
        />
      )}
      <InfoItem
        label={t('deviceDetail.deviceInformation.manufacturer')}
        value={data?.manufacturer}
      />
      <InfoItem
        label={t('deviceDetail.deviceInformation.deviceModel')}
        value={data?.model}
      />
      <InfoItem
        label={t('deviceDetail.deviceInformation.softwareVersion')}
        value={getSoftwareVersion()}
      />

      {!!data?.model?.includes('gateway') && updateGatewayStatus?.isUpdateAvailable && (
        <UpdateGatewayDialog
          isOpen={isUpdateGatewayDialogOpen}
          onOpenChange={(status) => setIsUpdateGatewayDialogOpen(status)}
          updateStatus={updateGatewayStatus}
        />
      )}

      {updateDeviceStatus?.isUpdateAvailable && (
        <UpdateDeviceDialog
          deviceTypeId={data?.type}
          isOpen={isUpdateDeviceDialogOpen}
          onOpenChange={(status) => setIsUpdateDeviceDialogOpen(status)}
          updateStatus={updateDeviceStatus}
        />
      )}
    </>
  );
}
