import { EscalationLayer } from '@eppendorf/vnls-notifications-utils';
import { t } from 'i18next';
import { Trans } from 'react-i18next';

import { NotificationsDetailsEscalationLayerCard } from '$features/notifications/detail/notification-detail-escalation-layer-card';
import { NotificationRuleEscalationLayerMode } from '$features/notifications/types';

import './notification-detail-escalation-layer.scss';

type NotificationsDetailEscalationLayerProps = {
  index: number;
  escalationLayer: EscalationLayer;
  totalLayers: number;
};

export function NotificationsDetailEscalationLayer({
  index,
  escalationLayer,
  totalLayers,
}: NotificationsDetailEscalationLayerProps) {
  const getEscalationMode = () => {
    let mode: string;

    if (totalLayers === 3) {
      if (index === 2) {
        mode = NotificationRuleEscalationLayerMode.STOP;
      } else {
        mode = NotificationRuleEscalationLayerMode.ESCALATE;
      }
    } else if (totalLayers === 2) {
      if (index === 1) {
        mode = NotificationRuleEscalationLayerMode.STOP;
      } else {
        mode = NotificationRuleEscalationLayerMode.ESCALATE;
      }
    } else {
      mode = NotificationRuleEscalationLayerMode.STOP;
    }

    return mode;
  };

  const escalationMode = getEscalationMode();

  const getEscalationModeLabel = () =>
    escalationMode === NotificationRuleEscalationLayerMode.STOP
      ? t('escalationLayer.stopNotifications')
      : t('escalationLayer.escalate');

  return (
    <div className="notification-detail-escalation-layer__item">
      <NotificationsDetailsEscalationLayerCard
        index={index}
        recipients={escalationLayer.recipients}
        escalateAfter={escalationLayer.escalateAfter}
      />
      <div className="notification-detail-escalation-layer__notifications-badge p-x-l p-y-xs font-weight-semibold font-size-s border-s border-solid border-color-gray-500">
        {getEscalationModeLabel()}
      </div>
      {escalationMode === NotificationRuleEscalationLayerMode.STOP && (
        <div>
          <Trans
            i18nKey="escalationLayer.unresolvedEventLabel"
            components={{ bold: <span className="font-weight-bold" /> }}
          />
        </div>
      )}
    </div>
  );
}
