import type {
  DeviceModels,
  DeviceTypeConfig,
} from '@eppendorf/vnls-application-configuration-service-types';
import {
  Button,
  Dialog,
  Icon,
  IconSizeClasses,
  Select,
  type SelectOption,
} from '@eppendorf/vnls-react-components';
import cn from 'classnames';
import {
  endOfMonth,
  format,
  startOfDay,
  startOfMonth,
  subDays,
  subMonths,
} from 'date-fns';
import { useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { useTokenAttributes } from '$shared/custom-hooks/useTokenAttributes';

import { useEmailMonitoringExportWithQueryParams } from '$features/monitoring/export/monitoring-export.api';
import { createEmailRequestParameter } from '$features/monitoring/export/monitoring-export.utils';
import { useRealtimeDevice } from '$features/monitoring/monitoring.graphql.api';
import { useDeviceTypeConfigMergedParams } from '$features/monitoring/shared/useDeviceTypeConfigMergedParams';

import styles from './email-monitoring-export.module.scss';

const getLastThreeMonths = (today: Date) => {
  const months = [];
  for (let i = 0; i < 3; i += 1) {
    const date = subMonths(today, i);
    const firstDay = startOfMonth(date);
    const lastDay = endOfMonth(date);
    months.push({
      name: format(date, 'MMMM'),
      year: date.getFullYear(),
      start: firstDay.toISOString(),
      end: lastDay.toISOString(),
    });
  }
  return months;
};

const getLastDays = (today: Date, daysBefore: number) => {
  const startDay = startOfDay(subDays(today, daysBefore));

  return [startDay.toISOString(), today.toISOString()];
};

export function EmailMonitoringExport() {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const { email } = useTokenAttributes(['email']);

  const options = useMemo(() => {
    const today = new Date();
    const allOptions = [
      {
        label: t('monitoringDetail.emailExportDetail.last7Days'),
        value: getLastDays(today, 7).join(','),
      },
    ];

    allOptions.push(
      ...getLastThreeMonths(today).map((month) => ({
        label: `${month.name} ${month.year}`,
        value: `${month.start},${month.end}`,
      })),
    );

    allOptions.push({
      label: t('monitoringDetail.emailExportDetail.lastXDays', { days: 90 }),
      value: getLastDays(today, 90).join(','),
    });

    return allOptions;
  }, []);

  const [selectedOption, setSelectedOption] = useState<SelectOption>(options[0]);
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);

  const deviceManufacturer = searchParams.get('manufacturer')!;
  const deviceSerialNumber = searchParams.get('serialNumber')!;

  const { data: realtimeDataDevice } = useRealtimeDevice(
    deviceManufacturer,
    deviceSerialNumber,
  );

  const deviceTypeConfig: DeviceTypeConfig<DeviceModels> | null =
    useDeviceTypeConfigMergedParams(realtimeDataDevice);

  const getParams = () => {
    const timeStamps = selectedOption?.value?.split(',');
    const start = timeStamps?.[0] ?? '';
    const end = timeStamps?.[1] ?? '';
    const timespan = selectedOption?.label ?? '';
    const ianaTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    return createEmailRequestParameter(
      deviceTypeConfig,
      realtimeDataDevice,
      start,
      end,
      timespan,
      ianaTimezone,
    );
  };

  const { isError, isPending, mutate, isSuccess } =
    useEmailMonitoringExportWithQueryParams(getParams());

  const startDownload = async () => mutate();

  useEffect(() => {
    if (!isPending && isSuccess) {
      setOpenConfirmationDialog(true);
    }
  }, [isPending, isSuccess]);

  return (
    <div className="flex flex__dir--column flex__ai--center p-xxl w-full h-full text-align-center">
      <span className="m-bottom-l">
        <Icon name="csv" className="bg-blue-500" size={IconSizeClasses.Large} />
      </span>

      <div className="font-size-xl font-weight-medium m-bottom-m">
        <Trans i18nKey="monitoringDetail.emailExportDetail.title" />
      </div>
      <div className="m-bottom-m">
        <Trans
          i18nKey="monitoringDetail.emailExportDetail.hint"
          components={{ br: <br /> }}
        />
      </div>
      <div className="m-bottom-xxl">
        <Trans
          i18nKey="monitoringDetail.emailExportDetail.note"
          components={{ bold: <span className="font-weight-bold" /> }}
        />
      </div>

      <div
        className={`flex__dir--row w-xl flex__ai--center gap-l ${cn(styles.exportRanges)}`}
      >
        <Select
          defaultValue={options[0].value}
          options={options}
          onChange={(option) =>
            setSelectedOption(
              options.find((x) => x.value === option) ?? ({} as SelectOption),
            )
          }
        />
        <Button onClick={startDownload} disabled={isPending}>
          <Trans i18nKey="monitoringDetail.emailExportDetail.buttonText" />
        </Button>
      </div>

      {isError && (
        <div className="font-size-xl font-weight-medium color-red-600">
          <Trans i18nKey="monitoringDetail.emailExportDetail.errorMessage" />
        </div>
      )}

      <Dialog
        isOpen={openConfirmationDialog}
        onOpenChange={(state) => setOpenConfirmationDialog(state)}
        hideCloseButton
        className="email-monitoring-export"
      >
        <div className="flex flex__dir--column flex__ai--center">
          <Icon
            name="info"
            size={IconSizeClasses.XLarge}
            className="bg-blue-500 m-bottom-xxl"
          />
          <Dialog.Title>
            <Trans i18nKey="monitoringDetail.emailExportDetail.successMessage" />
          </Dialog.Title>
          <Dialog.Description>
            <Trans
              i18nKey="monitoringDetail.emailExportDetail.successMessageHint"
              components={{ bold: <span className="font-weight-bold" /> }}
              values={{ email }}
            />
          </Dialog.Description>
        </div>
        <Dialog.Actions>
          <Button onClick={() => setOpenConfirmationDialog(false)} className="btn--solid">
            {t('shared.close')}
          </Button>
        </Dialog.Actions>
      </Dialog>
    </div>
  );
}
