import React from 'react';

import { Card } from '$components/card';

type IncidentDetailCardProps = {
  header: React.ReactNode;
  children: React.ReactNode;
  showTail?: boolean;
  deviceCard?: boolean;
  variant?: 'default' | 'success';
};

export function IncidentDetailCard({
  header,
  showTail = true,
  deviceCard = false,
  variant,
  children,
}: IncidentDetailCardProps) {
  return (
    <Card variant={variant}>
      {showTail && <Card.Tail />}
      <Card.Header spacingY={deviceCard ? 'large' : 'small'} variant={variant}>
        {header}
      </Card.Header>
      <Card.Body>{children}</Card.Body>
    </Card>
  );
}
