import { ReactNode } from 'react';

import './card.scss';

type CardBodyProps = {
  children?: ReactNode;
};

export function CardBody({ children }: CardBodyProps) {
  return <div className="flex__jc--center p-xl">{children}</div>;
}
