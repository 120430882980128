import { ButtonBase } from '@eppendorf/vnls-react-components';
import './incident-detail-acknowledge-footer.scss';
import { useTranslation } from 'react-i18next';

type IncidentDetailAcknowledgeFooterProps = {
  acknowledgeIncident: () => void;
  acknowledgingIncident: boolean;
};

export function IncidentDetailAcknowledgeFooter({
  acknowledgeIncident,
  acknowledgingIncident,
}: IncidentDetailAcknowledgeFooterProps) {
  const { t } = useTranslation();

  return (
    <div className="incident-detail-acknowledge-footer">
      <div className="incident-detail-acknowledge-footer__content">
        <ButtonBase
          onClick={acknowledgeIncident}
          className="btn-base btn btn--primary btn--success btn--medium"
          disabled={acknowledgingIncident}
          title="Acknowledge Incident"
        >
          {t('incidents.acknowledgeIncident')}
        </ButtonBase>
      </div>
    </div>
  );
}
