import {
  ActivityCode,
  UserActivityCode,
  DeviceActivityCode,
  NotificationActivityCode,
  PlatformActivityCode,
} from '@eppendorf/vnls-audit-event-client';
import type { IconProps } from '@eppendorf/vnls-react-components';

// Define a complete type of all possible activity code translation keys
type ActivityTranslationKey =
  | `auditEvents.description.${UserActivityCode}`
  | `auditEvents.description.${DeviceActivityCode}`
  | `auditEvents.description.${NotificationActivityCode}`
  | `auditEvents.description.${PlatformActivityCode}`
  | 'auditEvents.description.unknown';

const USER_ACTIVITY_CODES = new Set(Object.values(UserActivityCode));
const DEVICE_ACTIVITY_CODES = new Set(Object.values(DeviceActivityCode));
const NOTIFICATION_ACTIVITY_CODES = new Set(Object.values(NotificationActivityCode));
const PLATFORM_ACTIVITY_CODES = new Set(Object.values(PlatformActivityCode));

export const getIconNameForActivityCode = (code: ActivityCode): IconProps['name'] => {
  if (USER_ACTIVITY_CODES.has(code as UserActivityCode)) {
    return 'users_unmasked';
  }
  if (DEVICE_ACTIVITY_CODES.has(code as DeviceActivityCode)) {
    return 'generic_unmasked';
  }
  if (NOTIFICATION_ACTIVITY_CODES.has(code as NotificationActivityCode)) {
    return 'notifications_unmasked';
  }
  if (PLATFORM_ACTIVITY_CODES.has(code as PlatformActivityCode)) {
    return 'maintenance_unmasked';
  }

  return 'placeholder_unmasked';
};

export const getActivityTranslationKey = (
  activityCode: ActivityCode,
): ActivityTranslationKey => {
  const isValidCode =
    USER_ACTIVITY_CODES.has(activityCode as UserActivityCode) ||
    DEVICE_ACTIVITY_CODES.has(activityCode as DeviceActivityCode) ||
    NOTIFICATION_ACTIVITY_CODES.has(activityCode as NotificationActivityCode) ||
    PLATFORM_ACTIVITY_CODES.has(activityCode as PlatformActivityCode);

  return `auditEvents.description.${isValidCode ? activityCode : 'unknown'}`;
};
