import { Button, Icon, IconSizeClasses } from '@eppendorf/vnls-react-components';
import type { FunctionComponent, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { useFeatureIsEnabled } from '$components/feature-toogle/feature-is-enabled';

import './notification-detail-footer.scss';

export interface NotificationDetailFooterProps {
  onEditDialogOpen: () => void;
  onArchiveNotificationRule: () => void;
  canArchive: boolean;
}

export function NotificationDetailFooter({
  onEditDialogOpen,
  onArchiveNotificationRule,
  canArchive,
}: NotificationDetailFooterProps): ReactElement<
  NotificationDetailFooterProps,
  FunctionComponent<NotificationDetailFooterProps>
> {
  const { t } = useTranslation();

  const archiveEnabled = useFeatureIsEnabled('archiveNotificationRules') && canArchive;

  if (!archiveEnabled) {
    return (
      <div className="m-top-xxl flex flex__jc--flex-end">
        <Button size="small" onClick={() => onEditDialogOpen()}>
          {t('shared.edit')}
        </Button>
      </div>
    );
  }

  return (
    <div className="m-top-xxl flex flex__jc--space-between w-full notification-detail-footer">
      <Button
        className="notification-detail-footer__delete-button"
        leadingIcon={
          <Icon name="delete" size={IconSizeClasses.XSmall} className="bg-red-500" />
        }
        variant="tertiary"
        color="danger"
        size="small"
        onClick={() => onArchiveNotificationRule()}
      >
        {t('shared.delete')}
      </Button>
      <Button size="small" onClick={() => onEditDialogOpen()}>
        {t('shared.edit')}
      </Button>
    </div>
  );
}
