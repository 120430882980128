import { getFormattedLocalizedDateTimeWithSeconds } from '$shared/utils/date.utils';

type IncidentDetailAcknowledgedContentProps = {
  acknowledgedAt: number | undefined;
  acknowledgedBy: string;
};

export function IncidentDetailAcknowledgedContent({
  acknowledgedAt,
  acknowledgedBy,
}: IncidentDetailAcknowledgedContentProps) {
  return (
    <div className="flex gap-l flex__dir--column flex__ai--center">
      <div className="flex gap-m flex__dir--column text-align-center">
        {acknowledgedAt && (
          <div>{getFormattedLocalizedDateTimeWithSeconds(acknowledgedAt)}</div>
        )}
      </div>
      <div className="flex gap-s flex__dir--column">
        <div className="font-weight-semibold">{acknowledgedBy}</div>
      </div>
    </div>
  );
}
