import { Dialog, Icon, IconSizeClasses } from '@eppendorf/vnls-react-components';
import { useTranslation } from 'react-i18next';

import { getFormattedLocalizedDateTimeWithSeconds } from '$shared/utils/date.utils';

export interface IncidentDetailHeaderProps {
  incidentCreatedAt?: number;
  notificationRuleName?: string;
}

export function IncidentDetailHeader({
  incidentCreatedAt,
  notificationRuleName,
}: IncidentDetailHeaderProps) {
  const { t } = useTranslation();

  return (
    <div className="flex flex__ai--center">
      <div className="flex__ai--center bg-blue-500 border-radius-xxl p-xs">
        <Icon name="alarm-ringing" size={IconSizeClasses.XSmall} className="bg-white" />
      </div>
      <div className="m-left-s m-right-l">
        <Dialog.Title className="m-bottom-xxs">
          {incidentCreatedAt &&
            getFormattedLocalizedDateTimeWithSeconds(incidentCreatedAt)}
        </Dialog.Title>
        <div>
          {t('incidents.triggeredBy')} {notificationRuleName}
        </div>
      </div>
    </div>
  );
}
