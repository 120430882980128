import type { EnrichedUser } from '@eppendorf/vnls-audit-event-client';
import { Icon, IconSizeClasses } from '@eppendorf/vnls-react-components';

import { AffectedBadge } from '$features/audit-events/components/affected/affected-badge';

interface AffectedUserEntityProps {
  entities: EnrichedUser[];
}

export function AffectedUserEntity({ entities }: AffectedUserEntityProps) {
  if (!entities?.length) {
    return null;
  }

  const [firstUser, ...rest] = entities;

  return (
    <div className="flex flex__ai--center">
      <Icon name="users_unmasked" size={IconSizeClasses.Small} className="m-right-s" />
      <div className="whitespace-nowrap ellipsize">
        {firstUser.firstName} {firstUser.lastName}
      </div>
      <AffectedBadge value={rest.length} />
    </div>
  );
}
