interface SidebarNumberBadgeProps {
  number: number | undefined;
}

function getBadgeWidth(num: number): string {
  switch (num) {
    case 0:
      return '0px';
    case 1:
      return '20px';
    case 2:
      return '25px';
    case 3:
      return '30px';
    default:
      return '35px';
  }
}

export function SidebarNumberBadge({ number }: SidebarNumberBadgeProps) {
  if (!number) {
    return null;
  }

  return (
    <div
      className="color-white bg-red-500 border-radius-xxl border-s border-color-white font-size-xs flex flex__ai--center flex__jc--center bs-default overflow-hidden"
      style={{ height: '20px', width: getBadgeWidth(number.toString().length) }}
    >
      {number}
    </div>
  );
}
