import { Sidecar } from '@eppendorf/vnls-react-components';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useRouteError, useSearchParams } from 'react-router-dom';

import { useCurrentUserId } from '$shared/custom-hooks/useCurrentUserId';
import { useTokenAttributes } from '$shared/custom-hooks/useTokenAttributes';
import { isFeatureEnabled } from '$shared/feature-toggle/is-feature-enabled';

import { LoadingErrorHint } from '$components/loading-error-hint/loading-error-hint';
import { ControlledSidecar } from '$components/sidecar/controlled-sidecar';
import { SidecarRouterOutlet } from '$components/sidecar-router/sidecar-router-outlet';
import { UserDetailNavigation } from '$features/show-user/user-detail-navigation';
import { useGetUser } from '$features/users/users.api';
import { getStatusDisplay } from '$features/users-overview/users-overview';

export function UserDetailSidecar() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const routeError = useRouteError();
  const [isSidecarOpen, setIsSidecarOpen] = useState(true);
  const [searchParams] = useSearchParams();
  const userId = searchParams.get('id') || '';
  const { data: user, isError } = useGetUser(userId);
  const isPreferencesFeatureEnabled = isFeatureEnabled('showUserPreferences');

  const { email, tenantId } = useTokenAttributes(['email', 'tenantId']);
  const currentUserId = useCurrentUserId(email, tenantId);

  const statusBadge = user?.status ? getStatusDisplay(user?.status, true) : null;
  const isCurrentUser = currentUserId === user?.id;
  const titleName = isCurrentUser
    ? t('userDetail.myProfile')
    : `${user?.firstName} ${user?.lastName}`;

  const handleSidecarOpenChange = () => {
    setIsSidecarOpen(false);
    navigate({
      search: '',
    });
  };

  return (
    <ControlledSidecar isOpen={isSidecarOpen} onOpenChange={handleSidecarOpenChange}>
      {isError || routeError ? (
        <LoadingErrorHint className="m-top-xl" />
      ) : (
        <div className="flex flex__dir--column flex__ai--stretch h-full">
          <Sidecar.Title>
            <div className="flex flex__ai--center m-right-m m-bottom-m">
              <div className="m-right-l">
                <h2 className="title">{titleName}</h2>
              </div>
              <div className="m-right-l">{statusBadge}</div>
            </div>
          </Sidecar.Title>
          <div className="box overflow-y-auto">
            {isPreferencesFeatureEnabled && (
              <div className="box__header m-bottom-xl">
                <UserDetailNavigation />
              </div>
            )}
            <SidecarRouterOutlet />
          </div>
        </div>
      )}
    </ControlledSidecar>
  );
}
