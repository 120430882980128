import { Icon, IconSizeClasses } from '@eppendorf/vnls-react-components';
import { useTranslation } from 'react-i18next';

import { useDeviceType } from '$components/app-config/useDeviceType';
import { UpdatedDevice } from '$features/devices/devices.types';
import { searchDeviceIcon } from '$features/devices/devices.utils';

type DeviceToUpdateProps = {
  device: UpdatedDevice;
};

export function DeviceToUpdate({ device }: DeviceToUpdateProps) {
  const { t } = useTranslation();
  const deviceType = useDeviceType(device);

  return (
    <div key={`device-update-${device.name}`} className="flex flex__ai--center">
      <Icon size={IconSizeClasses.Small} name={searchDeviceIcon(deviceType ?? '')} />

      <div className="m-left-s text-align-left">
        <div className="body-bold">
          {device.name || t('deviceDetail.fallbackHeadlineNameEmpty')}
        </div>
        <div className="color-gray-800">{device.model}</div>
      </div>
    </div>
  );
}
