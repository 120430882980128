import { Icon, IconSizeClasses, Input } from '@eppendorf/vnls-react-components';
import { useCallback, useState } from 'react';
import { useDebounce } from 'react-use';

import { isFeatureEnabled } from '$shared/feature-toggle/is-feature-enabled';

export interface SearchInputProps {
  onSearchQueryChange: (value: string) => void;
  placeholder?: string;
  id?: string;
  debounceDelay?: number;
  className?: string;
  isEnabled?: boolean;
}

export function SearchInput({ isEnabled = false, ...props }: SearchInputProps) {
  const searchFeatureEnabled = isFeatureEnabled('deviceSearch');
  const { onSearchQueryChange, placeholder, id, debounceDelay = 500, className } = props;
  const [searchQuery, setSearchQuery] = useState<string>('');

  const [, cancelDebounce] = useDebounce(
    () => {
      onSearchQueryChange(searchQuery?.trim());
    },
    debounceDelay,
    [searchQuery],
  );

  const handleSearchQueryChange = useCallback((value: string): void => {
    setSearchQuery(value);
    // The debounced onSearchQueryChange will be called after the debounce delay
  }, []);

  const handleClearSearch = useCallback((): void => {
    setSearchQuery('');
    cancelDebounce();
    onSearchQueryChange(''); // Immediately call with empty string when clearing
  }, [onSearchQueryChange, cancelDebounce]);

  return (
    (searchFeatureEnabled || isEnabled) && (
      <Input
        type="text"
        value={searchQuery}
        onChange={(e) => handleSearchQueryChange(e.target.value)}
        placeholder={placeholder}
        id={id || 'search-input'}
        leadingVisual={<Icon name="search" size={IconSizeClasses.XSmall} />}
        className={className}
        trailingAction={
          <Input.Action
            onClick={() => handleClearSearch()}
            icon={
              searchQuery ? (
                <Icon name="close" size={IconSizeClasses.XSmall} />
              ) : (
                <div style={{ width: '24rem', height: '24rem' }} />
              )
            }
          />
        }
      />
    )
  );
}
