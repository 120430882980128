import { Sidecar } from '@eppendorf/vnls-react-components';
import { type ReactElement, useState } from 'react';
import { useNavigate, useRouteError, useSearchParams } from 'react-router-dom';

import { LoadingErrorHint } from '$components/loading-error-hint/loading-error-hint';
import { SidecarRouterOutlet } from '$components/sidecar-router/sidecar-router-outlet';
import { IncidentDetailHeader } from '$features/incidents/incident-detail-header';
import { useGetIncident } from '$features/incidents/incidents.api';
import { IncidentsStatusBadge } from '$features/incidents-list/incidents-status';

export function IncidentDetailSidecar(): ReactElement {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [isSidecarOpen, setIsSidecarOpen] = useState(true);
  const routeError = useRouteError();
  const { isError, data } = useGetIncident(searchParams.get('id') || '');

  function handleSidecarOpenChange() {
    setIsSidecarOpen(false);
    navigate({ search: '' });
  }

  return (
    <Sidecar isOpen={isSidecarOpen} onOpenChange={() => handleSidecarOpenChange()}>
      {(routeError || isError || !data) && <LoadingErrorHint className="m-top-xl" />}

      {data && (
        <div className="flex flex__dir--column flex__ai--stretch h-full">
          <div className="flex flex__ai--center m-bottom-xxxl">
            <IncidentDetailHeader
              incidentCreatedAt={data?.createdAt}
              notificationRuleName={data?.notificationRule.name}
            />
            <div className="m-right-m">
              <div className="flex flex__ai--center">
                <IncidentsStatusBadge incidentStatus={data?.incidentStatus} hasBorder />
              </div>
            </div>
          </div>
          <div className="box">
            <SidecarRouterOutlet />
          </div>
        </div>
      )}
    </Sidecar>
  );
}
