import { Badge } from '@eppendorf/vnls-react-components';

export interface AffectedBadgeProps {
  value: string | number;
}

export function AffectedBadge({ value }: AffectedBadgeProps) {
  if (!Number(value)) {
    return null;
  }

  return (
    <Badge className="m-left-s whitespace-nowrap" color="blue" size="small">
      {`+ ${value}`}
    </Badge>
  );
}
