import type { IncidentListDTO } from '@eppendorf/vnls-notifications-utils';
import {
  Icon,
  IconSizeClasses,
  VirtualizedTable,
} from '@eppendorf/vnls-react-components';
import { createColumnHelper } from '@tanstack/react-table';
import { ReactElement, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, useNavigate, useRouteError, useSearchParams } from 'react-router-dom';

import { isFeatureEnabled } from '$shared/feature-toggle/is-feature-enabled';

import { CellTimestamp } from '$components/cell-timestamp/cell-timestamp';
import { DeviceNameCell } from '$components/device/name-cell';
import { LoadingErrorHint } from '$components/loading-error-hint/loading-error-hint';
import { useIncidentsWithQueryParams } from '$features/incidents/incidents.api';
import { IncidentListEmptyMessage } from '$features/incidents-list/incident-list-empty-message';
import { IncidentUnreadIndicator } from '$features/incidents-list/incident-unread-indicator';
import { IncidentsStatusBadge } from '$features/incidents-list/incidents-status';
import { getEventIconName } from '$features/monitoring/events/events.utils';
import { isValueWithKeyInSearchParams } from '$features/navigation/isValueWithKeyInSearchParams';
import { AddNotificationRuleDialog } from '$features/notifications/add-or-edit-notification-rule-dialog';

export function IncidentsList(): ReactElement {
  const { t } = useTranslation();
  const showIncidentsTab = isFeatureEnabled('showIncidentsTab');
  const routeError = useRouteError();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const handleRowClick = (incident: IncidentListDTO) => {
    navigate({
      search: `?sidecarRoute=incident/flow&id=${incident.id}`,
    });
  };

  const [isNotificationDialogOpen, setIsNotificationDialogOpen] = useState(false);

  const { data, isError, isLoading, isFetching, fetchNextPage, hasNextPage } =
    useIncidentsWithQueryParams();
  const incidents = data?.pages.flat() ?? [];
  const columnHelper = createColumnHelper<IncidentListDTO>();
  const columns = useMemo(
    () => [
      columnHelper.accessor((info) => info, {
        id: 'incidentStatus',
        header: () => t('shared.status'),
        // eslint-disable-next-line react/no-unstable-nested-components -- accepted
        cell: (info) => (
          <>
            <IncidentUnreadIndicator isRead={info.getValue().isRead} />
            <IncidentsStatusBadge incidentStatus={info.getValue().incidentStatus} />
          </>
        ),
      }),
      columnHelper.accessor('createdAt', {
        header: () => t('incidentsTable.createdAt'),
        // eslint-disable-next-line react/no-unstable-nested-components -- accepted
        cell: (info) => <CellTimestamp timestamp={info.renderValue()} />,
      }),
      columnHelper.accessor('eventMessages.level', {
        header: () => t('incidentsTable.type'),
        // eslint-disable-next-line react/no-unstable-nested-components -- accepted
        cell: ({
          row: {
            original: { eventMessages: notificationsMessageInfo },
          },
        }) => {
          const notification = notificationsMessageInfo[0];

          return (
            <Icon
              name={`event-${getEventIconName(notification?.level)}`}
              size={IconSizeClasses.XSmall}
            />
          );
        },
      }),
      columnHelper.accessor('eventMessages.message', {
        header: () => t('incidentsTable.event'),
        cell: (info) => info.row.original.eventMessages[0]?.message,
      }),
      columnHelper.accessor('device.deviceName', {
        header: () => t('incidentsTable.device'),
        // eslint-disable-next-line react/no-unstable-nested-components -- accepted
        cell: ({
          row: {
            original: { device },
          },
        }) => <DeviceNameCell device={device} isIconUnmasked />,
      }),
      columnHelper.accessor('notificationRule', {
        header: () => t('incidentsTable.triggeredBy'),
        cell: (info) => info.row.original.notificationRule.name,
      }),
    ],
    [],
  );

  return showIncidentsTab ? (
    <>
      {isNotificationDialogOpen && (
        <AddNotificationRuleDialog
          isOpen={isNotificationDialogOpen}
          onOpenChange={(open) => setIsNotificationDialogOpen(open)}
        />
      )}

      <div className="p-bottom-m p-top-m">{t('incidents.hint')}</div>
      {(routeError || isError) && <LoadingErrorHint />}
      {!isLoading && !isError && (
        <div className="flex flex__dir--column" style={{ height: '100vh' }}>
          {incidents && (
            <VirtualizedTable
              data={incidents || []}
              columns={columns}
              loadMore={() => {
                if (hasNextPage && !isFetching) {
                  fetchNextPage();
                }
              }}
              onRowClick={handleRowClick}
              isSelectedRow={(incident: IncidentListDTO) =>
                isValueWithKeyInSearchParams('id', incident.id, searchParams)
              }
              isLoading={isFetching}
            />
          )}

          {!isLoading && !isError && (!incidents || incidents?.length === 0) && (
            <IncidentListEmptyMessage
              openNotificationDialog={setIsNotificationDialogOpen}
            />
          )}
        </div>
      )}
    </>
  ) : (
    <Navigate to="/notifications/notifications-rules" replace />
  );
}
