import { Button, Dialog, Icon, IconSizeClasses } from '@eppendorf/vnls-react-components';
import { t } from 'i18next';
import { useCallback, useRef, ReactNode, useState } from 'react';

import { useAutoFocus } from '$shared/custom-hooks/useAutoFocus';

export interface ConfirmDeleteDialogProps {
  isOpen?: boolean;
  onClose: () => void;
  onConfirmDelete: () => Promise<void>;
  title: ReactNode;
  description: ReactNode;
}

export function ConfirmDeleteDialog({
  isOpen,
  onClose,
  onConfirmDelete,
  title,
  description,
}: ConfirmDeleteDialogProps) {
  const [deleting, setDeleting] = useState(false);

  const handleOpenChange = useCallback(
    (open: boolean) => {
      if (!open) {
        onClose();
      }
    },
    [onClose],
  );

  const handleConfirmDelete = useCallback(() => {
    setDeleting(true);
    onConfirmDelete().finally(() => {
      setDeleting(false);
    });
  }, [onConfirmDelete]);

  const closeButtonRef = useRef<HTMLButtonElement>(null);

  useAutoFocus(closeButtonRef);

  return (
    <Dialog
      isOpen={isOpen}
      onOpenChange={handleOpenChange}
      className="w-max-4xl"
      hideCloseButton={false}
    >
      <div className="flex flex__dir--column flex__ai--center text-align-center">
        <Icon
          name="delete"
          className="bg-red-500 m-bottom-xxl"
          size={IconSizeClasses.Large}
        />
        <Dialog.Title>{title}</Dialog.Title>
        <Dialog.Description className="m-bottom-xxs">{description}</Dialog.Description>
      </div>
      <Dialog.Actions className="m-top-xxl">
        <Dialog.Close>
          <Button ref={closeButtonRef} variant="secondary">
            {t('shared.cancel')}
          </Button>
        </Dialog.Close>
        <Button
          variant="primary"
          color="danger"
          onClick={handleConfirmDelete}
          disabled={deleting}
        >
          {t('shared.delete')}
        </Button>
      </Dialog.Actions>
    </Dialog>
  );
}
