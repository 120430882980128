import { type EnrichedAuditEvent } from '@eppendorf/vnls-audit-event-client';
import { FetchInfiniteQueryOptions, QueryClient } from '@tanstack/react-query';
import { ReactElement } from 'react';
import { LoaderFunctionArgs } from 'react-router-dom';

import { isFeatureEnabledInCache } from '$shared/feature-toggle/is-feature-enabled';
import { prepopulateInfiniteQueryCache } from '$shared/utils/react-query.utils';

import {
  createGetFeatureToggleQuery,
  appConfigQueryKey,
} from '$components/app-config/app-config.api';
import { AuditEventsOverview } from '$features/audit-events/audit-events-overview';
import { createAuditEventsQuery } from '$features/audit-events/audit-events.api';

export async function auditEventsLoader(
  queryClient: QueryClient,
  args: LoaderFunctionArgs,
): Promise<null> {
  const isEnabled = await isFeatureEnabledInCache(
    queryClient,
    'showAuditEvents',
    createGetFeatureToggleQuery(),
    [appConfigQueryKey, 'feature-toggles'],
  );

  if (!isEnabled) {
    return Promise.resolve(null);
  }

  const url = new URL(args.request.url);
  const searchParams = Object.fromEntries(url.searchParams.entries());
  const from = searchParams.from ?? null;
  const to = searchParams.to ?? null;

  return prepopulateInfiniteQueryCache<EnrichedAuditEvent[]>(
    queryClient,
    createAuditEventsQuery(from, to) as FetchInfiniteQueryOptions<EnrichedAuditEvent[]>,
  );
}

export function AuditEventsPage(): ReactElement {
  return <AuditEventsOverview />;
}
