import { ActivityCode, EntityType } from '@eppendorf/vnls-audit-event-client';
import {
  Button,
  Dialog,
  DialogProps,
  Icon,
  IconSizeClasses,
} from '@eppendorf/vnls-react-components';
import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { useCommitAuditEvent } from '$shared/audit-log';
import { isFeatureEnabled } from '$shared/feature-toggle/is-feature-enabled';
import { createAuditEvent } from '$shared/utils/create-audit-event';

import { DeviceToUpdate } from '$features/devices/device-information/update-dialogs/device-to-update';
import { useOtaUpdateDevice } from '$features/devices/devices.api';
import {
  DeviceUpdateStatus,
  PublishDeviceUpdateResponse,
} from '$features/devices/devices.types';

type UpdateDialogProps = Pick<DialogProps, 'isOpen' | 'onOpenChange'> & {
  deviceTypeId?: string;
  updateStatus: DeviceUpdateStatus;
};

export function UpdateDeviceDialog({
  deviceTypeId = '',
  isOpen = false,
  onOpenChange,
  updateStatus,
}: UpdateDialogProps) {
  const { t } = useTranslation();
  const [isSuccessOpen, setIsSuccessOpen] = useState(false);
  const [updateResponse, setUpdateResponse] = useState<PublishDeviceUpdateResponse>();
  const { mutateAsync: update, isPending } = useOtaUpdateDevice();
  const affectedDevices = updateStatus.devices.map((device) => ({
    type: EntityType.Device,
    id: device.serialNumber ?? '',
  }));
  const devicesUpdated = updateStatus.devices.map((device) => ({
    id: device.serialNumber ?? '',
    oldVersion: device.softwareRevision,
    newVersion: updateStatus.appliedSoftwareVersion,
  }));
  const { mutate: commit } = useCommitAuditEvent();
  const commitAuditEventEnabled = isFeatureEnabled('commitAuditEvent');
  const updateDevices = async () => {
    if (commitAuditEventEnabled) {
      const auditEvent = await createAuditEvent(ActivityCode.DEVICE_UPDATE_STARTED);
      const event = auditEvent.withAffected(affectedDevices).withMetadata({
        v: '1',
        devicesUpdated,
      });
      commit(event);
    }
    const { data } = await update(deviceTypeId);
    setUpdateResponse(data);
    setIsSuccessOpen(true);
  };
  useEffect(() => {
    if (!isPending && onOpenChange) {
      onOpenChange(false);
    }
  }, [isPending]);
  return (
    <>
      <Dialog
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        className="w-6xl w-max-6xl"
        hideCloseButton
      >
        <div className="flex flex__dir--column flex__ai--center">
          {isPending && <div className="loader" />}
          <Icon
            name="info"
            className="bg-blue-500 m-bottom-xxl"
            size={IconSizeClasses.XLarge}
          />
          <Dialog.Title>
            {t('deviceDetail.deviceInformation.updateDialog.title')}
          </Dialog.Title>
          <Dialog.Description className="m-bottom-xxs">
            <div>
              <Trans
                i18nKey="deviceDetail.deviceInformation.updateDialog.description"
                components={{ bold: <span className="font-weight-bold" /> }}
                values={{
                  deviceType: updateStatus.devices[0].model,
                  version: updateStatus.appliedSoftwareVersion,
                }}
              />
            </div>
            <div className="m-y-4xl h-max-s overflow-scroll">
              {updateStatus.devices.map((device) => (
                <DeviceToUpdate key={device.serialNumber} device={device} />
              ))}
            </div>
            <div className="m-top-xl">
              <Trans
                i18nKey="deviceDetail.deviceInformation.updateDialog.disclaimer"
                components={{ bold: <span className="font-weight-bold" /> }}
              />
            </div>
          </Dialog.Description>
        </div>
        <Dialog.Actions className="m-top-xxl">
          <Dialog.Close>
            <Button variant="secondary">{t('shared.later')}</Button>
          </Dialog.Close>
          <Button onClick={updateDevices} disabled={isPending}>
            {t('shared.updateNow')}
          </Button>
        </Dialog.Actions>
      </Dialog>

      {updateResponse?.version && (
        <Dialog
          isOpen={isSuccessOpen}
          onOpenChange={(status) => {
            setIsSuccessOpen(status);
          }}
          className="w-6xl w-max-6xl"
          hideCloseButton
        >
          <div className="flex flex__dir--column flex__ai--center">
            <Icon
              name="info"
              className="bg-blue-500 m-bottom-xxl"
              size={IconSizeClasses.XLarge}
            />
            <Dialog.Title>
              {t('deviceDetail.deviceInformation.updatePublishedDialog.title')}
            </Dialog.Title>
            <Dialog.Description className="m-bottom-xxs">
              <div className="m-top-xl">
                <Trans
                  i18nKey="deviceDetail.deviceInformation.updatePublishedDialog.disclaimer"
                  components={{ bold: <span className="font-weight-bold" /> }}
                  values={{
                    version: updateResponse?.version,
                  }}
                />
              </div>
            </Dialog.Description>
          </div>
          <Dialog.Actions className="m-top-xxl">
            <Dialog.Close>
              <Button variant="secondary">{t('shared.close')}</Button>
            </Dialog.Close>
          </Dialog.Actions>
        </Dialog>
      )}
    </>
  );
}
