import cn from 'classnames';
import { ReactNode } from 'react';

import './card.scss';

type InternalCardProps = {
  children?: ReactNode;
  variant?: 'default' | 'success';
  className?: string;
};

export function InternalCard({
  children,
  className,
  variant = 'default',
}: InternalCardProps) {
  return (
    <div
      className={cn(
        'card-internal w-max border-radius-l border-s border-solid',
        {
          'border-color-gray-500': variant === 'default',
          'border-color-green-500': variant === 'success',
        },
        className,
      )}
    >
      {children}
    </div>
  );
}
