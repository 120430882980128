import type { EscalationLayer as EscalationLayerType } from '@eppendorf/vnls-notifications-utils';
import {
  SortableTable,
  Select,
  Checkbox,
  Icon,
  IconSizeClasses,
} from '@eppendorf/vnls-react-components';
import { User } from '@eppendorf/vnls-user-tenant-utils';
import { CellContext, createColumnHelper } from '@tanstack/react-table';
import cn from 'classnames';
import { t } from 'i18next';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Trans } from 'react-i18next';

import { Card } from '$components/card';
import { useFeatureIsEnabled } from '$components/feature-toogle/feature-is-enabled';
import { SearchInput } from '$components/search-input/search-input';
import { ButtonToggle } from '$features/monitoring/history-charts/components/button-toggle/button-toggle';
import { escalateAfterOptions } from '$features/notifications/add-or-edit-notification-rule-dialog/constants';
import { EscalationLayerHeaderContent } from '$features/notifications/add-or-edit-notification-rule-dialog/screens/components/escalation-layer/escalation-layer-header-content';
import { NotificationRuleEscalationLayerMode } from '$features/notifications/types';

import './escalation-layer.scss';

interface EscalationLayerWithMode extends EscalationLayerType {
  mode: NotificationRuleEscalationLayerMode;
}

type EscalationLayerProps = {
  index: number;
  data: User[];
  layer: EscalationLayerWithMode;
  onRowClick: (layerIndex: number, user: User) => void;
  onToggleMode: (layerIndex: number, mode: NotificationRuleEscalationLayerMode) => void;
  onEscalateAfterChange: (layerIndex: number, value: string) => void;
  escalationLayers: EscalationLayerWithMode[];
  isInfoOnly: boolean;
};

export function EscalationLayer({
  index,
  data,
  layer,
  onRowClick,
  onToggleMode,
  onEscalateAfterChange,
  escalationLayers,
  isInfoOnly,
}: EscalationLayerProps) {
  const [dirtyFields, setDirtyFields] = useState<Record<number, boolean>>({});
  const escalationLayersCount = escalationLayers.length;
  const recipientsCount = layer.recipients.length;
  const canAddEscalationLayer = useFeatureIsEnabled('canAddEscalationLayer');

  const [searchQuery, setSearchQuery] = useState<string>('');

  useEffect(() => {
    setDirtyFields({});
  }, []);

  const handleAddEscalation = () => {
    onToggleMode(index, NotificationRuleEscalationLayerMode.ESCALATE);
  };

  const handleOnRowClick = (layerIndex: number, user: User) => {
    setDirtyFields((prev) => ({
      ...prev,
      [layerIndex]: true,
    }));
    onRowClick(layerIndex, user);
  };

  const handleSearchQueryChange = useCallback((value: string) => {
    setSearchQuery(value);
  }, []);

  const filteredData = data.filter((user) => {
    const query = searchQuery.toLowerCase();
    return (
      user?.firstName?.toLowerCase().includes(query) ||
      user?.lastName?.toLowerCase().includes(query) ||
      user.email.toLowerCase().includes(query)
    );
  });

  const getItems = useMemo(() => {
    const isEscalationAllowed = !isInfoOnly;
    if (!canAddEscalationLayer) {
      return [
        {
          id: NotificationRuleEscalationLayerMode.STOP,
          text: t('escalationLayer.stopNotifications'),
          active: true,
          onClick: () => {},
        },
      ];
    }

    let computedMode;

    if (escalationLayersCount === 1) {
      if (index === 0) computedMode = NotificationRuleEscalationLayerMode.STOP;
    } else if (escalationLayersCount === 2) {
      if (index === 0) computedMode = NotificationRuleEscalationLayerMode.ESCALATE;
      if (index === 1) computedMode = NotificationRuleEscalationLayerMode.STOP;
    } else if (escalationLayersCount === 3) {
      if (index === 0) computedMode = NotificationRuleEscalationLayerMode.ESCALATE;
      if (index === 1) computedMode = NotificationRuleEscalationLayerMode.ESCALATE;
      if (index === 2) computedMode = NotificationRuleEscalationLayerMode.STOP;
    } else if (layer.mode) {
      computedMode = layer.mode;
    }

    const baseItems = [
      {
        id: NotificationRuleEscalationLayerMode.STOP,
        text: t('escalationLayer.stopNotifications'),
        active: computedMode === NotificationRuleEscalationLayerMode.STOP,
        onClick: () => onToggleMode(index, NotificationRuleEscalationLayerMode.STOP),
      },
    ];

    if (isEscalationAllowed && index !== 2) {
      baseItems.push({
        id: NotificationRuleEscalationLayerMode.ESCALATE,
        text: t('escalationLayer.escalate'),
        active: computedMode === NotificationRuleEscalationLayerMode.ESCALATE,
        onClick: () => (recipientsCount === 0 ? () => {} : handleAddEscalation()),
      });
    }

    return baseItems;
  }, [
    layer.mode,
    index,
    recipientsCount,
    escalationLayersCount,
    isInfoOnly,
    canAddEscalationLayer,
    handleAddEscalation,
  ]);

  const isStopActive = getItems.some(
    (item) =>
      item.active === true && item.id === NotificationRuleEscalationLayerMode.STOP,
  );

  const showEscalationActions = useMemo(() => {
    if (
      index === 0 &&
      recipientsCount === 0 &&
      !isInfoOnly &&
      escalationLayersCount === 1
    ) {
      return false;
    }

    if (index === 0 && recipientsCount > 0 && !isInfoOnly) {
      return true;
    }
    if (!isInfoOnly) {
      return true;
    }

    return false;
  }, [recipientsCount, isInfoOnly, escalationLayers, escalationLayersCount, index]);

  const columnHelper = createColumnHelper<User>();

  const columns = (layerIndex: number) => [
    {
      id: 'checked',
      // eslint-disable-next-line react/no-unstable-nested-components -- we want this here
      cell: ({ row }: CellContext<User, string>) => {
        const user = row.original;
        const currentEscalationLayers = escalationLayers || [];

        const isSelectedInCurrentLayer =
          currentEscalationLayers
            .at(layerIndex)
            ?.recipients.some((u) => u.id === user.id) ?? false;

        return (
          <Checkbox
            checked={isSelectedInCurrentLayer}
            onChange={() => handleOnRowClick(layerIndex, user)}
          />
        );
      },
    },
    columnHelper.accessor('firstName', {
      id: 'firstName',
      // header: () => t('userManagement.firstName'),
      header: () => null,
      cell: (info) => info.renderValue(),
    }),
    columnHelper.accessor('lastName', {
      id: 'lastName',
      // header: () => t('userManagement.lastName'),
      header: () => null,
      cell: (info) => info.renderValue(),
    }),
    columnHelper.accessor('email', {
      id: 'email',
      // header: () => t('shared.email'),
      header: () => null,
      cell: (info) => info.renderValue(),
    }),
  ];

  const isLayerModeEscalate = layer.mode === NotificationRuleEscalationLayerMode.ESCALATE;
  const isLayerModeStop = layer.mode === NotificationRuleEscalationLayerMode.STOP;
  const isDirty = dirtyFields[Number(index) as keyof typeof dirtyFields];

  return (
    <>
      <Card className="escalation-layer escalation-layer__card">
        <div className="p-x-m">
          <EscalationLayerHeaderContent index={index} isInfoOnly={isInfoOnly} />
          {isDirty && recipientsCount <= 0 && escalationLayersCount > 1 && (
            <div className="escalation-layer__warning flex flex__ai--center bg-orange-500 color-white p-x-l border-radius-s m-bottom-m gap-s">
              <Icon name="warning" className="bg-white" size={IconSizeClasses.XSmall} />
              {t('escalationLayer.warning')}
            </div>
          )}
          <SearchInput
            onSearchQueryChange={handleSearchQueryChange}
            placeholder={t('escalationLayer.searchPlaceholder')}
            id="monitoring-overview-search"
            className="m-bottom-m search-input"
          />
          <SortableTable<User[], User>
            className="recipients-table"
            data={filteredData}
            columns={columns(index)}
            onRowClick={(user) => handleOnRowClick(index, user)}
          />
        </div>
        {showEscalationActions && (
          <Card.Footer spacingY="large">
            <div className="flex flex__ai--center gap-s">
              <p>{t('notificationRules.cardFooter')}</p>
              <div
                className="flex__dir--row w-xl flex__ai--center"
                style={{ width: 120 }}
              >
                <Select
                  value={layer.escalateAfter.toString()}
                  onChange={(e) => onEscalateAfterChange(index, e)}
                  options={escalateAfterOptions}
                  ariaLabel="escalation time"
                />
              </div>
            </div>
          </Card.Footer>
        )}
      </Card>

      {showEscalationActions && (
        <div
          className={cn('escalation-layer__actions relative flex flex__jc--center', {
            'escalation-layer__actions--stop': isLayerModeStop || isStopActive,
          })}
        >
          <div className="escalation-layer__tail--before" />
          <ButtonToggle
            testId={`button-toggle-${index}`}
            className="escalation-layer__button-toggle"
            items={getItems}
          />
          {isLayerModeEscalate && <div className="escalation-layer__tail--after" />}
        </div>
      )}
      {showEscalationActions && (isLayerModeStop || isStopActive) && (
        <div className="escalation-layer__label text-align-center">
          <Trans
            i18nKey="escalationLayer.unresolvedEventLabel"
            components={{ bold: <span className="font-weight-bold" /> }}
          />
        </div>
      )}
    </>
  );
}
