import type { EnrichedAuditEvent } from '@eppendorf/vnls-audit-event-client';
import {
  Icon,
  IconSizeClasses,
  VirtualizedTable,
} from '@eppendorf/vnls-react-components';
import { createColumnHelper } from '@tanstack/react-table';
import { Dispatch, ReactElement, SetStateAction, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouteError } from 'react-router-dom';

import { CellTimestamp } from '$components/cell-timestamp/cell-timestamp';
import { LoadingErrorHint } from '$components/loading-error-hint/loading-error-hint';
import { useAuditEventsQuery } from '$features/audit-events/audit-events.api';
import {
  getIconNameForActivityCode,
  getActivityTranslationKey,
} from '$features/audit-events/audit-events.utils';
import { AffectedCell } from '$features/audit-events/components/affected/affected-cell';
import { PerformedByCell } from '$features/audit-events/components/performed-by/performed-by-cell';

type AuditEventsListProps = {
  from: string | null;
  to: string | null;
  setSearchParams: Dispatch<SetStateAction<URLSearchParams>>;
};

export function AuditEventsList({
  from,
  to,
  setSearchParams,
}: AuditEventsListProps): ReactElement {
  const { t } = useTranslation();
  const routeError = useRouteError();
  const { data, isError, isLoading, isFetching, fetchNextPage, hasNextPage } =
    useAuditEventsQuery(from, to);
  const auditLogs = data?.pages.flat() ?? [];
  const columnHelper = createColumnHelper<EnrichedAuditEvent>();

  /* eslint-disable react/no-unstable-nested-components -- accepted */
  const columns = useMemo(
    () => [
      columnHelper.accessor('timestamp', {
        header: t('auditEvents.header.timestamp'),
        cell: (info) => <CellTimestamp timestamp={info.renderValue()} />,
      }),
      columnHelper.accessor('activityCode', {
        header: t('auditEvents.header.type'),
        cell: (info) => (
          <Icon
            name={getIconNameForActivityCode(info.getValue())}
            size={IconSizeClasses.Small}
          />
        ),
      }),
      columnHelper.accessor('metadata.originEntity', {
        header: t('auditEvents.header.performedBy'),
        cell: (info) => <PerformedByCell entity={info.getValue()} />,
        meta: {
          cell: {
            className: 'w-max-l',
          },
        },
      }),
      columnHelper.accessor('activityCode', {
        id: 'description-id',
        header: t('auditEvents.header.description'),
        cell: (info) => t(getActivityTranslationKey(info.getValue())),
      }),
      columnHelper.accessor('metadata', {
        header: t('auditEvents.header.details'),
        cell: (info) => (
          <AffectedCell
            entities={info.getValue()?.affectedEntities}
            deviceType={info.getValue()?.selectedDeviceType as string}
          />
        ),
        meta: {
          cell: {
            className: 'w-max-l',
          },
        },
      }),
    ],
    [t],
  );
  /* eslint-enable react/no-unstable-nested-components */
  const resetSearch = useCallback(() => {
    setSearchParams((prev) => {
      prev.delete('from');
      prev.delete('to');
      return prev;
    });
  }, []);

  return (
    <>
      {(routeError || isError) && <LoadingErrorHint />}

      {!isLoading && !isError && (
        <div
          className="flex flex__dir--column"
          style={{ height: auditLogs && auditLogs.length > 0 ? '100vh' : '50vh' }}
        >
          {auditLogs && (
            <VirtualizedTable
              data={auditLogs}
              columns={columns}
              loadMore={() => {
                if (hasNextPage && !isFetching) {
                  fetchNextPage();
                }
              }}
              isLoading={isFetching}
              onResetSearch={resetSearch}
            />
          )}
        </div>
      )}
    </>
  );
}
