import { IncidentStatus, IncidentStatusEnum } from '@eppendorf/vnls-notifications-utils';
import { Badge } from '@eppendorf/vnls-react-components';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

interface IncidentsStatusBadgeProps {
  incidentStatus?: IncidentStatus;
  hasBorder?: boolean;
}

export function IncidentsStatusBadge({
  incidentStatus,
  hasBorder,
}: IncidentsStatusBadgeProps): ReactElement {
  const { t } = useTranslation();
  function getColorBadgeByStatus(status?: IncidentStatus) {
    switch (status) {
      case IncidentStatusEnum.NoActionRequired:
        return (
          <Badge color="green" bordered={hasBorder} className="text-align-center">
            {t('incidents.incidentsStatus.noActionRequired')}
          </Badge>
        );

      case IncidentStatusEnum.Acknowledged:
        return (
          <Badge color="green" bordered={hasBorder}>
            {t('incidents.incidentsStatus.acknowledged')}
          </Badge>
        );

      case IncidentStatusEnum.Escalated_1:
        return (
          <Badge color="red" bordered={hasBorder}>
            {t('incidents.incidentsStatus.escalated_1')}
          </Badge>
        );

      case IncidentStatusEnum.Escalated_2:
        return (
          <Badge color="red" bordered={hasBorder}>
            {t('incidents.incidentsStatus.escalated_2')}
          </Badge>
        );

      case IncidentStatusEnum.Resolved:
        return (
          <Badge color="green" bordered={hasBorder}>
            {t('incidents.incidentsStatus.resolved')}
          </Badge>
        );

      case IncidentStatusEnum.ActionRequired:
        return (
          <Badge className="bg-white text-align-center" bordered color="orange">
            {t('incidents.incidentsStatus.actionRequired')}
          </Badge>
        );

      case IncidentStatusEnum.Expired:
        return (
          <Badge className="badge-no-background-color" bordered color="red">
            {t('incidents.incidentsStatus.expired')}
          </Badge>
        );

      default:
        return (
          <Badge bordered={hasBorder}>{t('incidents.incidentsStatus.unavailable')}</Badge>
        );
    }
  }

  return getColorBadgeByStatus(incidentStatus);
}
