import {
  Button,
  Icon,
  IconSizeClasses,
  Input,
  Label,
  Tooltip,
  ValidationMessage,
} from '@eppendorf/vnls-react-components';
import { PhoneNumber, User, UserRole } from '@eppendorf/vnls-user-tenant-utils';
import { Dispatch, ReactElement, SetStateAction, useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { AccessControlGuard } from '$shared/access-control/access-control-guard';

import { CancelEditDialog } from '$components/cancel-edit-dialog/cancel-edit-dialog';
import {
  FeatureIsEnabled,
  useFeatureIsEnabled,
} from '$components/feature-toogle/feature-is-enabled';
import { RolesDropdown } from '$features/edit-user/roles-dropdown/roles-dropdown';
import { SMSCountrySelect } from '$features/edit-user/sms-country-dropdown/sms-country-dropdown';
import { getDisplayPhoneNumber } from '$features/edit-user/utils/get-display-phone-number';
import { hasPhoneNumberRemoved } from '$features/edit-user/utils/has-phone-number-removed';
import { EditUserForm } from '$features/show-user/form-wrapper';
import { PhoneNumberRemovedDialogState } from '$features/show-user/phone-number-removed-dialog/phone-number-removed-dialog';
import { PhoneNumberUpdatedDialogState } from '$features/show-user/phone-number-updated-dialog/phone-number-updated-dialog';
import { usePreferencesState } from '$features/show-user/preferences/hooks/usePreferencesState';
import { stringifyUserRoles } from '$features/users/roles.utils';
import { useUpdateUser } from '$features/users/users.api';

import { hasPhoneNumberOrCountryCodeChanged } from './utils/has-phone-number-or-country-code-changed';

export interface EditViewProps {
  onEditViewCancel: () => void;
  onPhoneNumberAdded: () => void;
  onPhoneNumbersChange: (props: PhoneNumberUpdatedDialogState) => void;
  onPhoneNumbersRemoved: (props: PhoneNumberRemovedDialogState) => void;
  onRoleChange?: (role: UserRole) => void;
  dialogState: [boolean, Dispatch<SetStateAction<boolean>>];
  user: User;
  isCurrentUser: boolean;
}

export function EditView({
  onEditViewCancel,
  onPhoneNumberAdded,
  onPhoneNumbersChange,
  onPhoneNumbersRemoved,
  dialogState: [isCancelDialogOpen, setIsCancelDialogOpen],
  user,
  isCurrentUser,
}: EditViewProps): ReactElement {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { preferences, refetch: invalidatePreferences } = usePreferencesState();

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors: formErrors, isSubmitted, isValid, dirtyFields },
  } = useFormContext<EditUserForm>();

  const handleRoleChange = (newRole: UserRole) => {
    setValue('roles', [newRole], { shouldDirty: true });
  };
  const {
    mutateAsync,
    error: updateUserError,
    isPending: isUpdateUserLoading,
    isSuccess: isUpdateUserSuccess,
  } = useUpdateUser();

  const isAddUpdatePhoneNumberFeatEnabled = useFeatureIsEnabled('addUpdatePhoneNumber');

  const resetToReadView = (): void => {
    reset(user);
    setIsCancelDialogOpen(false);
    onEditViewCancel();
  };

  const resetToEditView = (): void => {
    setIsCancelDialogOpen(false);
  };

  useEffect(() => {
    setValue('roles', user.roles || []);

    if (updateUserError) {
      resetToEditView();
    }

    if (isUpdateUserSuccess) {
      resetToReadView();
    }

    if (isCancelDialogOpen && isUpdateUserSuccess) {
      resetToEditView();
      navigate(-1);
    }
  }, [isUpdateUserSuccess, updateUserError, isCancelDialogOpen, user, setValue]);

  useEffect(() => {
    if (formErrors.firstName || formErrors.lastName) {
      resetToEditView();
    }
  }, [formErrors.firstName, formErrors.lastName]);

  const getPhoneNumbers = (
    isUpdatingCurrentUser: boolean,
    phoneNumbers: PhoneNumber[] | undefined,
  ): PhoneNumber[] | undefined => {
    if (!isUpdatingCurrentUser) return undefined;

    const [phoneNumber] = phoneNumbers || [];
    const { countryCode, number, status } = phoneNumber || {};

    if (countryCode && number) {
      return [{ countryCode, number, status }];
    }

    return [];
  };

  const handleUpdateUser = async (formData: EditUserForm): Promise<boolean> => {
    const isPhoneNumberOrCountryCodeDirty =
      dirtyFields.phoneNumbers?.[0].number || dirtyFields.phoneNumbers?.[0].countryCode;

    try {
      await mutateAsync({
        id: user.id,
        firstName: formData.firstName,
        lastName: formData.lastName,
        roles: isCurrentUser ? undefined : formData.roles,
        phoneNumbers: isPhoneNumberOrCountryCodeDirty
          ? getPhoneNumbers(isCurrentUser, formData.phoneNumbers)
          : undefined,
      });
      return true;
    } catch {
      // eslint-disable-next-line no-useless-return -- disables test error output
      return false;
    }
  };

  const handleFormSubmit = async (formData: EditUserForm): Promise<void> => {
    if (!user || !formData || !formData.roles) {
      throw new Error('Invalid formData');
    }

    const currentPhoneNumbers = getPhoneNumbers(isCurrentUser, user?.phoneNumbers);
    const newPhoneNumbers = getPhoneNumbers(isCurrentUser, formData.phoneNumbers);
    const phoneNumberOrCountryCodeChanged = hasPhoneNumberOrCountryCodeChanged(
      currentPhoneNumbers,
      newPhoneNumbers,
    );

    const phoneNumberRemoved = hasPhoneNumberRemoved(
      currentPhoneNumbers,
      newPhoneNumbers,
    );

    const response = await handleUpdateUser(formData);
    const currentPreferences = { ...preferences };

    // Check if the phone number has been removed
    // trigger the phone number removed dialog
    if (phoneNumberRemoved && response) {
      // Invalidate preferences to update the communication preferences.
      await invalidatePreferences();

      onPhoneNumbersRemoved({
        isOpen: true,
        currentPhoneNumbers,
        allowSmsCommunication: currentPreferences.allowSmsCommunication,
      });
    }

    // check if a phone number was added
    if (newPhoneNumbers?.find((phoneNumber) => phoneNumber.status === undefined)) {
      onPhoneNumberAdded();
    }

    // Check if the phone number has changed
    // trigger the phone number updated dialog
    if (phoneNumberOrCountryCodeChanged && response) {
      // Invalidate preferences to update the communication preferences.
      await invalidatePreferences();

      onPhoneNumbersChange({
        isOpen: true,
        currentPhoneNumbers,
        newPhoneNumbers,
        allowSmsCommunication: currentPreferences.allowSmsCommunication,
      });
    }
  };

  const handleDialogOnOpenChange = (): void => {
    resetToEditView();
  };

  const handleDialogDiscard = (): void => {
    resetToReadView();
    navigate(-1);
  };

  const handleDialogSaveChanges = (): void => {
    handleSubmit(handleFormSubmit)();
  };

  const readOnlyRoles = (
    <p className="font-weight-bold m-bottom-xs">{stringifyUserRoles(user.roles || [])}</p>
  );

  const displayPhoneNumber = useMemo(
    () => getDisplayPhoneNumber(user.phoneNumbers),
    [user.phoneNumbers],
  );

  const saveDisabled =
    (isSubmitted && !isValid) || isUpdateUserLoading || !!Object.keys(formErrors).length;

  return (
    <>
      {user && (
        <form onSubmit={handleSubmit(handleFormSubmit)}>
          <div className="m-bottom-m">
            <Label htmlFor="firstName">{t('userManagement.firstName')}*</Label>
            <Input
              invalid={!!formErrors.firstName}
              className="m-top-xs"
              id="firstName"
              disabled={isUpdateUserLoading}
              type="text"
              // eslint-disable-next-line react/jsx-props-no-spreading -- spread is needed for react-hook-form
              {...register('firstName', {
                required: true,
                maxLength: 120,
              })}
            />
            {formErrors.firstName?.type === 'required' && (
              <ValidationMessage>{t('validationMessages.required')}</ValidationMessage>
            )}
            {formErrors.firstName?.type === 'maxLength' && (
              <ValidationMessage>
                {t('validationMessages.maxLength120')}
              </ValidationMessage>
            )}
          </div>

          <div className="m-bottom-m">
            <Label htmlFor="lastName">{t('userManagement.lastName')}*</Label>
            <Input
              invalid={!!formErrors.lastName}
              className="m-top-xs"
              id="lastName"
              disabled={isUpdateUserLoading}
              type="text"
              // eslint-disable-next-line react/jsx-props-no-spreading -- spread is needed for react-hook-form
              {...register('lastName', {
                required: true,
                maxLength: 120,
              })}
            />
            {formErrors.lastName?.type === 'required' && (
              <ValidationMessage>{t('validationMessages.required')}</ValidationMessage>
            )}
            {formErrors.lastName?.type === 'maxLength' && (
              <ValidationMessage>
                {t('validationMessages.maxLength120')}
              </ValidationMessage>
            )}
          </div>

          <h3 className="m-bottom-xs">{t('shared.email')}</h3>
          <p className="font-weight-bold m-bottom-xs">{user.email}</p>
          <h3 className="m-bottom-l color-gray-800">{t('userManagement.emailNote')}</h3>

          {isAddUpdatePhoneNumberFeatEnabled && (
            <div className="m-bottom-m">
              <div className="flex flex__ai--flex-end m-bottom-xs">
                <Label htmlFor="phoneNumbers" className="m-right-xs">
                  {isCurrentUser
                    ? t('userManagement.phoneNumberOptional')
                    : t('userManagement.phoneNumber')}
                </Label>

                {isCurrentUser ? (
                  <Tooltip
                    trigger={
                      <div>
                        <Icon name="info" size={IconSizeClasses.XSmall} />
                      </div>
                    }
                  >
                    <Trans
                      i18nKey="userManagement.limitedSupportedCountriesNotice"
                      components={{ br: <br /> }}
                    />
                  </Tooltip>
                ) : null}
              </div>

              {!isCurrentUser ? (
                <p className="font-weight-bold m-bottom-xs">{displayPhoneNumber}</p>
              ) : (
                <>
                  <div className="flex flex__dir--row h-3xs m-bottom-m">
                    <div className="col-7" style={{ marginRight: '0px' }}>
                      <SMSCountrySelect
                        id="phoneNumbers.0.countryCode"
                        required={!!dirtyFields.phoneNumbers?.[0]?.number}
                      />
                    </div>
                    <div className="col-5 m-left-m w-full">
                      <Input
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any -- test
                        invalid={!!(formErrors.phoneNumbers as any)?.[0]?.number}
                        id="phoneNumbers.0.number"
                        disabled={isUpdateUserLoading}
                        type="string"
                        // eslint-disable-next-line react/jsx-props-no-spreading -- It is safe to spread register
                        {...register('phoneNumbers.0.number', {
                          minLength: 5,
                          pattern: /^[1-9]\d*$/,
                          onChange: (event) => {
                            const currentValue = event.target.value;
                            // allow only digits without leading zero(s)
                            const newValue = currentValue.replace(/^(0+)|\D/g, '');
                            if (currentValue !== newValue) {
                              setValue('phoneNumbers.0.number', newValue);
                            }
                          },
                        })}
                      />
                    </div>
                  </div>
                  {
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- test
                    (formErrors.phoneNumbers as any)?.[0]?.number?.type ===
                      'minLength' && (
                      <ValidationMessage>
                        {t('validationMessages.phoneNumberMinLength')}
                      </ValidationMessage>
                    )
                  }
                  {
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- test
                    (formErrors.phoneNumbers as any)?.[0]?.number?.type === 'pattern' && (
                      <ValidationMessage>
                        {t('validationMessages.invalidPhoneNumber')}
                      </ValidationMessage>
                    )
                  }
                  {
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- test
                    (formErrors.phoneNumbers as any)?.[0]?.countryCode?.type ===
                      'required' && (
                      <ValidationMessage>
                        {t('validationMessages.countryCodeRequired')}
                      </ValidationMessage>
                    )
                  }
                  <h3 className="m-bottom-xs color-gray-800">
                    {t('userManagement.verifyPhoneNumberNotice')}
                  </h3>
                </>
              )}
            </div>
          )}

          <h3 className="m-bottom-xs">{t('userManagement.role')}</h3>

          <FeatureIsEnabled feature="editRole" fallback={readOnlyRoles}>
            {isCurrentUser ? (
              <>
                {readOnlyRoles}
                <h3 className="m-bottom-xs color-gray-800">
                  {t('userManagement.contactAdmin')}
                </h3>
              </>
            ) : (
              <AccessControlGuard
                requiredPermissions="UPDATE_OTHERS_ROLE"
                fallbackComponent={readOnlyRoles}
              >
                <RolesDropdown
                  disabled={isUpdateUserLoading}
                  currentRole={watch('roles')?.[0] as UserRole}
                  onRoleChange={handleRoleChange}
                />
                <h3 className="m-bottom-l p-top-s color-gray-800">
                  {t('userManagement.roleUpdate5MinNotice')}
                </h3>
              </AccessControlGuard>
            )}
          </FeatureIsEnabled>

          {updateUserError && (
            <ValidationMessage className="m-bottom-m">
              {t('errors.unexpected')}
            </ValidationMessage>
          )}

          <div className="dialog-content__actions-wrapper m-top-xxl">
            <Button
              onClick={resetToReadView}
              variant="secondary"
              size="small"
              disabled={isUpdateUserLoading}
            >
              {t('shared.cancel')}
            </Button>
            <Button type="submit" size="small" disabled={saveDisabled}>
              {t('shared.save')}
            </Button>
          </div>
        </form>
      )}

      <CancelEditDialog
        isOpen={isCancelDialogOpen}
        onOpenChange={handleDialogOnOpenChange}
        onDiscardChanges={handleDialogDiscard}
        onSaveChanges={handleDialogSaveChanges}
        isLoading={isUpdateUserLoading}
      />
    </>
  );
}
