import { EscalationLayer } from '@eppendorf/vnls-notifications-utils';
import { Icon, IconSizeClasses } from '@eppendorf/vnls-react-components';
import { ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { NotificationsDetailEscalationLayer } from '$features/notifications/detail/notification-detail-escalation-layer';
import { renderEscalateAfter } from '$features/notifications/detail/utils';

import './notification-detail-recipient.scss';

type NotificationDetailRecipientProps = {
  escalationLayers: EscalationLayer[];
  tags: string[];
};

export function NotificationDetailRecipient({
  escalationLayers,
  tags,
}: NotificationDetailRecipientProps): ReactElement {
  const escalationLayersCount = escalationLayers.length;
  const { t } = useTranslation();

  const renderTitle = useMemo(() => {
    if (escalationLayersCount === 1)
      return t(`notificationDetail.oneGroupEscalationLayerDescription`, {
        timeOne: renderEscalateAfter(escalationLayers[0].escalateAfter),
      });
    if (escalationLayersCount === 2)
      return t(`notificationDetail.twoGroupsEscalationLayerDescription`, {
        timeOne: renderEscalateAfter(escalationLayers[0].escalateAfter),
        timeTwo: renderEscalateAfter(escalationLayers[1].escalateAfter),
      });
    if (escalationLayersCount === 3)
      return t(`notificationDetail.threeGroupsEscalationLayerDescription`, {
        timeOne: renderEscalateAfter(escalationLayers[0].escalateAfter),
        timeTwo: renderEscalateAfter(escalationLayers[1].escalateAfter),
        timeThree: renderEscalateAfter(escalationLayers[2].escalateAfter),
      });

    return null;
  }, [escalationLayersCount, escalationLayers]);

  const renderEscalationLayers = useMemo(() => {
    if (escalationLayersCount === 1 && tags.length === 1 && tags.includes('info')) {
      return escalationLayers[0].recipients.map((recipient, index) => (
        <div
          key={recipient.name}
          className={
            index === escalationLayers[0].recipients.length - 1 ? '' : 'p-bottom-s'
          }
        >
          {recipient.name}
        </div>
      ));
    }

    return (
      <>
        <div className="m-bottom-xxl">{renderTitle}</div>
        <div className="notification-detail-recipient__notifications-badge font-weight-semibold p-m border-s border-solid border-color-gray-500 m-bottom-xxl">
          <div className="notification-detail-recipient__notifications-badge--icon">
            <Icon
              size={IconSizeClasses.XXSmall}
              name="alarm-ringing"
              className="bg-white"
            />
          </div>
          {t('notificationDetail.notificationFrequencyTitle', {
            value: `${5} ${t('shared.min')}`,
          })}
        </div>
        {escalationLayers.map((escalationLayer, index) => (
          <NotificationsDetailEscalationLayer
            key={`escalation-layer-${index.toString()}`}
            escalationLayer={escalationLayer}
            index={index}
            totalLayers={escalationLayers.length}
          />
        ))}
      </>
    );
  }, [escalationLayersCount, escalationLayers, t, tags]);

  return (
    <div className="recipients row p-top-xl">
      <div className="col-3">
        <h3 className="body-bold">{t('notificationDetail.recipients')}</h3>
      </div>
      <div className="recipients-list col-9 ">{renderEscalationLayers}</div>
    </div>
  );
}
