import {
  NotificationRulesQueryParams,
  PaginatedResponse,
} from '@eppendorf/vnls-notification-service-types';
import { NotificationRule } from '@eppendorf/vnls-notifications-utils';
import {
  keepPreviousData,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';

import { fetcher } from '$shared/fetch';
import {
  createArchiveNotificationRulePath,
  createNotificationRulePath,
  createPathWithQueryParams,
  getNotificationRuleBasePath,
  objectToURLSearchParams,
} from '$shared/utils/api-paths';

export const notificationRulesQueryKey = 'notification-rules';
export const addedNotificationRuleQueryKey = 'added-notification-rule';

// TODO remove useYggdrasil when feature flag will be removed.
export const createNotificationRulesQueryWithQueryParams = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- we don't know the actual type
  queryParams: NotificationRulesQueryParams,
  useYggdrasil: boolean | undefined,
) => ({
  queryKey: [notificationRulesQueryKey, ...objectToURLSearchParams(queryParams).values()],
  queryFn: () =>
    fetcher.get<PaginatedResponse<NotificationRule>>(
      createPathWithQueryParams(getNotificationRuleBasePath(useYggdrasil), queryParams),
    ),
  placeholderData: keepPreviousData,
});

export const createGetNotificationRuleByIdQuery = (
  id: string,
  useYggdrasil: boolean | undefined,
) => ({
  queryKey: [notificationRulesQueryKey, id],
  queryFn: () =>
    fetcher.get<NotificationRule>(createNotificationRulePath(id, useYggdrasil)),
  enabled: !!id,
});

export const useGetNotificationRule = (id: string, useYggdrasil: boolean | undefined) =>
  useQuery(createGetNotificationRuleByIdQuery(id, useYggdrasil));

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- we don't know the actual type
export const useNotificationRulesWithQueryParams = (
  queryParams: NotificationRulesQueryParams,
  useYggdrasil: boolean | undefined,
) => useQuery(createNotificationRulesQueryWithQueryParams(queryParams, useYggdrasil));

export const useAddedNotificationRule = () =>
  useQuery({
    queryKey: [addedNotificationRuleQueryKey],
    queryFn: () => [],
  });

export const useAddNotificationRule = (useYggdrasil: boolean | undefined) => {
  const addNotificationRule = (notificationRule: Omit<NotificationRule, 'id'>) =>
    fetcher.post<Omit<NotificationRule, 'id'>, NotificationRule>(
      getNotificationRuleBasePath(useYggdrasil),
      notificationRule,
    );

  const queryClient = useQueryClient();
  return useMutation<
    AxiosResponse<NotificationRule>,
    AxiosError,
    Omit<NotificationRule, 'id'>
  >({
    mutationFn: addNotificationRule,
    // This is sufficient for now. When we tackle performance we can utilise
    // optimistic updates or saving another single device request by mutating data instead
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [notificationRulesQueryKey] });

      queryClient.setQueryData([addedNotificationRuleQueryKey], {});
    },
  });
};

export const useUpdateNotificationRule = (useYggdrasil: boolean | undefined) => {
  const updateNotificationRule = (rule: NotificationRule) =>
    fetcher.patch<NotificationRule, NotificationRule>(
      createNotificationRulePath(rule.id, useYggdrasil),
      rule,
    );

  const queryClient = useQueryClient();
  return useMutation<NotificationRule, Error, NotificationRule>({
    mutationFn: updateNotificationRule,
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({ queryKey: [notificationRulesQueryKey] });

      queryClient.setQueryData([notificationRulesQueryKey, variables.id], data);
    },
  });
};

export const useArchiveNotificationRule = () => {
  const archiveNotificationRule = (id: string) =>
    fetcher.patch<never, NotificationRule>(createArchiveNotificationRulePath(id));

  const queryClient = useQueryClient();
  return useMutation<NotificationRule, Error, string>({
    mutationFn: archiveNotificationRule,
    onSuccess: (_, id) => {
      queryClient.invalidateQueries({ queryKey: [notificationRulesQueryKey] });
      queryClient.removeQueries({
        queryKey: [notificationRulesQueryKey, id],
        exact: true,
      });
    },
  });
};
