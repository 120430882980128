import {
  EntityType,
  type EnrichedDevice,
  type EnrichedEntity,
  type EnrichedNotificationRule,
  type EnrichedUser,
} from '@eppendorf/vnls-audit-event-client';
import { useTranslation } from 'react-i18next';

import { AffectedDeviceEntity } from '$features/audit-events/components/affected/affected-device-entity';
import { AffectedNotificationRuleEntity } from '$features/audit-events/components/affected/affected-notification-rule-entity';
import { AffectedPlatformEntity } from '$features/audit-events/components/affected/affected-platform';
import { AffectedUserEntity } from '$features/audit-events/components/affected/affected-user-entity';

const groupEntitiesByType = (entities: EnrichedEntity[]) => {
  const entityGroups: Record<EntityType, EnrichedEntity[]> = {
    [EntityType.User]: [],
    [EntityType.Device]: [],
    [EntityType.NotificationRule]: [],
    [EntityType.Platform]: [],
  };

  entities
    .filter((entity) => entity?.type)
    .forEach((entity) => entityGroups[entity.type].push(entity));

  return entityGroups;
};

interface AffectedCellProps {
  entities?: EnrichedEntity[];
  deviceType?: string;
}

export function AffectedCell({ entities, deviceType }: AffectedCellProps) {
  const { t } = useTranslation();

  if (!entities?.length) {
    return t('auditEvents.notAvailable');
  }

  const entityGroups = groupEntitiesByType(entities);

  return (
    <div className="flex flex__dir--column">
      <AffectedUserEntity entities={entityGroups[EntityType.User] as EnrichedUser[]} />
      <AffectedDeviceEntity
        entities={entityGroups[EntityType.Device] as EnrichedDevice[]}
        deviceType={deviceType}
      />
      <AffectedNotificationRuleEntity
        entities={entityGroups[EntityType.NotificationRule] as EnrichedNotificationRule[]}
      />
      <AffectedPlatformEntity entities={entityGroups[EntityType.Platform]} />
    </div>
  );
}
