import {
  DotsLoader,
  Icon,
  IconSizeClasses,
  SortableTable,
  SortableTableProps,
} from '@eppendorf/vnls-react-components';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import styles from './associated-devices.module.scss';

export interface AssociatedDevicesTableProps<T> {
  loading: boolean;
  columns: SortableTableProps<T[], T>['columns'];
  data: T[];
  emptyMessage?: string;
}

export function AssociatedDevicesTable<T>({
  loading,
  columns,
  data,
  emptyMessage,
}: AssociatedDevicesTableProps<T>) {
  const { t } = useTranslation();

  if (loading) {
    return <DotsLoader className="m-top-l" />;
  }

  if (!data.length) {
    return (
      <div className="flex flex__dir--column flex__ai--center p-xxxl">
        <Icon
          name="not-paired"
          size={IconSizeClasses.Large}
          className="bg-gray-500 m-bottom-l"
        />
        <p className="m-bottom-m font-weight-medium font-size-xl">
          {t('deviceDetail.associatedDevices.emptyTitle')}
        </p>
        <p className="m-bottom-xxl w-xl text-align-center">
          {emptyMessage ?? t('deviceDetail.associatedDevices.emptyMessage')}
        </p>
      </div>
    );
  }

  return (
    <div className={cn(styles.associatedDevicesTable)}>
      <SortableTable<T[], T> data={data} columns={columns} />
    </div>
  );
}
