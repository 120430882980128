import type {
  DeviceModels,
  DeviceParameterConfig,
  DeviceTypeConfig,
} from '@eppendorf/vnls-application-configuration-service-types';
import type { Device } from '@eppendorf/vnls-telemetry-and-events-types';

import type {
  MonitoringExportParameters,
  MonitoringExportQueryParams,
} from '$features/monitoring/export/monitoring-export.api';

function transformParameters(
  device: Device | undefined,
  parameter: DeviceParameterConfig,
): MonitoringExportParameters | undefined {
  const subject = device?.realtime?.find(
    (realTimeData) =>
      (realTimeData?.subject === parameter.subject ||
        realTimeData?.name === parameter.name) &&
      realTimeData?.dataType === 'telemetry',
  );

  return {
    subject: subject?.subject ?? parameter.subject,
    name: subject?.name ?? parameter.name,
  };
}
export function createRequestParameter(
  deviceTypeConfig: DeviceTypeConfig<DeviceModels> | null,
  realtimeDataDevice: Device | undefined,
): MonitoringExportQueryParams {
  const getSubject: MonitoringExportParameters[] = deviceTypeConfig?.parameters?.map(
    (param) => transformParameters(realtimeDataDevice, param),
  ) as MonitoringExportParameters[];

  return {
    serialNumber: realtimeDataDevice?.serialNumber || '',
    parameters: [...(getSubject ?? [])],
  };
}

interface EmailMonitoringExportQueryParams extends MonitoringExportQueryParams {
  startDate: string;
  endDate: string;
  exportPeriodName: string;
  ianaTimezone: string;
}

export function createEmailRequestParameter(
  deviceTypeConfig: DeviceTypeConfig<DeviceModels> | null,
  realtimeDataDevice: Device | undefined,
  startDate: string,
  endDate: string,
  exportPeriodName: string,
  ianaTimezone: string,
): EmailMonitoringExportQueryParams {
  const getSubject: MonitoringExportParameters[] = deviceTypeConfig?.parameters?.map(
    (param) => transformParameters(realtimeDataDevice, param),
  ) as MonitoringExportParameters[];

  return {
    serialNumber: realtimeDataDevice?.serialNumber || '',
    parameters: [...(getSubject ?? [])],
    startDate,
    endDate,
    exportPeriodName,
    ianaTimezone,
  };
}
