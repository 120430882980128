import cn from 'classnames';
import { FieldValues, useFormContext, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  getSMSCountry,
  supportedSMSCountriesOptions,
} from '$shared/utils/phone-number.utils';

import { Autocomplete } from '$components/autocomplete';

import styles from '../../devices/device-information/info-item.module.scss';

type ValueOf<T> = T[keyof T];

export function SMSCountrySelect<T extends FieldValues>({
  id,
  required,
}: {
  id: ValueOf<T>;
  required?: boolean;
}) {
  const { control } = useFormContext<T>();

  const { t } = useTranslation();

  return (
    <div className={cn('color-gray-900', 'body-regular', styles.value)}>
      <Controller
        control={control}
        rules={{ required }}
        render={({ field: { onChange, value } }) => (
          <Autocomplete
            options={supportedSMSCountriesOptions}
            value={getSMSCountry(value)}
            onChange={(option) => onChange(option.value)}
            placeholder={t('userManagement.countryCodeSelectPlaceHolder')}
            noOptionsMessage={() => t('deviceDetail.deviceInformation.noCountryOptions')}
            ariaLabelledBy="smscountry"
            menuPosition="top"
          />
        )}
        name={id}
      />
    </div>
  );
}
