import {
  type Device,
  Pagination as PaginiationType,
} from '@eppendorf/vnls-inventory-service-types';
import {
  Button,
  Icon,
  IconSizeClasses,
  SortableTable,
  useDidMountEffect,
} from '@eppendorf/vnls-react-components';
import { type Device as TelemetryDevice } from '@eppendorf/vnls-telemetry-and-events-types';
import { createColumnHelper } from '@tanstack/react-table';
import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useRouteError, useSearchParams } from 'react-router-dom';

import { AccessControlGuard } from '$shared/access-control/access-control-guard';
import { useAccessControlGuard } from '$shared/custom-hooks/useAccessControlGuard';
import { isFeatureEnabled } from '$shared/feature-toggle/is-feature-enabled';

import { LoadingErrorHint } from '$components/loading-error-hint/loading-error-hint';
import { Overview } from '$components/overview-box/overview-box';
import { SearchInput } from '$components/search-input/search-input';
import { Pagination } from '$components/table/pagination/pagination';
import { DeviceDialogWrapper } from '$features/add-device';
import { DeviceNameOrSerialNumber } from '$features/device-list/device-name-or-serialnumber';
import { DeviceTypeCell } from '$features/device-list/device-type-cell';
import { DeviceStatus } from '$features/devices/device-status/device-status';
import {
  useAddedDevices,
  useDeleteDevice,
  useDevicesWithQueryParams,
} from '$features/devices/devices.api';
import { useRealtimeDevices } from '$features/monitoring/monitoring.graphql.api';
import { isValueWithKeyInSearchParams } from '$features/navigation/isValueWithKeyInSearchParams';
import './device-list.scss';

function useDeleteDeviceHandler() {
  const { mutate } = useDeleteDevice();
  const deleteDeviceHandler = useCallback(
    async (device: Device) => {
      mutate(device);
    },
    [mutate],
  );
  return deleteDeviceHandler;
}

export function DeviceList(): ReactElement {
  const [searchQuery, setSearchQuery] = useState<string>('');
  const navigate = useNavigate();
  const routeError = useRouteError();
  const hasActionsPermission = useAccessControlGuard('DEREGISTER_DEVICE');
  const isPermissionsFeatureEnabled = isFeatureEnabled(
    'applyPermissionsToDeviceOverviewPage',
  );
  const isDeregisterDeviceFeatureEnabled = isFeatureEnabled('deregisterDevice');
  const [{ page, pageSize }, setPagination] = useState<PaginiationType>({
    page: 1,
    pageSize: 10,
  });

  const handleSearchQueryChange = useCallback(
    (newSearchQuery: string) => {
      if (searchQuery === newSearchQuery) {
        return;
      }
      setSearchQuery(newSearchQuery?.length > 0 ? newSearchQuery : '');
      setPagination((prev) => ({
        ...prev,
        page: 1,
      }));
    },
    [searchQuery, setSearchQuery, setPagination],
  );

  const [isDeviceDialogOpen, setIsDeviceDialogOpen] = useState(false);

  const devtoolingEnabled = isFeatureEnabled('devtooling');

  const { t } = useTranslation();

  const {
    data: paginatedData,
    isError,
    isLoading,
    refetch,
  } = useDevicesWithQueryParams({
    page,
    pageSize,
    searchQuery: searchQuery || undefined,
  });

  const {
    data: realtimeData,
    isFetching: isFetchingRealtimeDevices,
    refetch: refetchRealtimeDevices,
  } = useRealtimeDevices(page, pageSize, 'all', searchQuery || undefined);

  const { data: addQueryState } = useAddedDevices();

  useDidMountEffect(() => {
    refetch();
    refetchRealtimeDevices();
  }, [page, pageSize, searchQuery]);

  const devices = paginatedData ? paginatedData.data : [];

  const mergedDevicesData = useMemo(() => {
    if (!paginatedData?.data) return [];

    if (paginatedData?.data && !realtimeData?.data) {
      return paginatedData.data.map((device) => ({ ...device, realtime: [] }));
    }

    const realtimeDataMap = new Map(
      (realtimeData?.data ?? [])
        .filter((device) => device?.serialNumber)
        .map((device) => [device?.serialNumber, device]),
    );

    return paginatedData.data.map((device) => ({
      ...device,
      realtime: realtimeDataMap.get(device.serialNumber)?.realtime || [],
    }));
  }, [realtimeData, paginatedData]);

  useEffect(() => {
    if (addQueryState && addQueryState.length > 0) {
      setPagination({ pageSize, page: 1 });
    }
  }, [addQueryState]);

  function handleRowClick(device: Device) {
    navigate({
      search: `?sidecarRoute=device/details&manufacturer=${device.manufacturer}&serialNumber=${device.serialNumber}`,
    });
  }

  const handlePageSizeChange = (newPageSize: string) => {
    setPagination({ page: 1, pageSize: Number(newPageSize) });
  };

  const handlePageChange = (newPage: number) => {
    setPagination({ pageSize, page: newPage });
  };

  const handleDeleteDevice = useDeleteDeviceHandler();

  const columnHelper = createColumnHelper<
    Device & { realtime: TelemetryDevice['realtime'] }
  >();
  const columns = useMemo(() => {
    const cols = [
      columnHelper.accessor('realtime', {
        header: () => t('shared.status'),
        // eslint-disable-next-line react/no-unstable-nested-components -- should be fine
        cell: (info) => (
          <div className="realtime-cell">
            {isFetchingRealtimeDevices ? (
              <div className="realtime-cell__loading" />
            ) : (
              <DeviceStatus
                deviceStatus={info.row?.original?.connectionStatus}
                realtimeArray={info.getValue() ?? []}
              />
            )}
          </div>
        ),
      }),
      columnHelper.accessor('type', {
        header: () => t('deviceTable.type'),
        // eslint-disable-next-line react/no-unstable-nested-components -- should be fine
        cell: (info) => <DeviceTypeCell device={info.row.original} />,
      }),
      columnHelper.accessor('name', {
        header: () => t('deviceTable.name'),
        // eslint-disable-next-line react/no-unstable-nested-components -- should be fine
        cell: (info) => <DeviceNameOrSerialNumber device={info.row.original} />,
      }),
      columnHelper.accessor('model', {
        header: () => t('deviceTable.model'),
        cell: (info) => info.renderValue(),
      }),
      columnHelper.accessor('serialNumber', {
        header: () => t('devices.serialNumber'),
        cell: (info) => info.renderValue(),
      }),
      columnHelper.accessor('registrationNumber', {
        header: () => t('devices.inventoryId'),
        cell: (info) => info.renderValue(),
      }),
      // using same accessor means you have to manually define unique ids (if no ids set, then
      // the accessor name is used for id and this results in errors if using same accessore multiple times)
      columnHelper.accessor('location', {
        header: () => t('deviceDetail.deviceInformation.site'),
        cell: (info) => info.getValue()?.site,
        id: 'site',
      }),
      columnHelper.accessor('location', {
        header: () => t('deviceDetail.deviceInformation.building'),
        cell: (info) => info.getValue()?.building,
        id: 'building',
      }),
      columnHelper.accessor('location', {
        header: () => t('deviceDetail.deviceInformation.room'),
        cell: (info) => info.getValue()?.room,
        id: 'room',
      }),
    ];

    const actionColumn = columnHelper.display({
      id: 'actions',
      header: () => t('shared.actions'),
      // eslint-disable-next-line react/no-unstable-nested-components -- accepted
      cell: ({ row }) => (
        // The problem: When rendering the Dialog multiple times for each row,
        // the Dialog re-renders (=closes itself) when the row is re-rendered
        // (e.g. when /devices are being refetched)
        // The solution: We render the Dialog only once, and control it via state.
        <Button
          onClick={(e) => {
            e.stopPropagation();
            handleDeleteDevice(row.original);
          }}
          variant="icon"
        >
          <Icon name="delete" size={IconSizeClasses.XSmall} />
        </Button>
      ),
    });

    // for now we only allow the deregister feature in DEV/STA and
    // since no other actions are defined yet we won't show action colum at all if the following condition is false
    if (isDeregisterDeviceFeatureEnabled && hasActionsPermission && devtoolingEnabled) {
      return [...cols, actionColumn];
    }
    return cols;
  }, [isDeregisterDeviceFeatureEnabled, hasActionsPermission, isFetchingRealtimeDevices]);

  const [searchParams] = useSearchParams();

  return (
    <Overview>
      <DeviceDialogWrapper
        isOpen={isDeviceDialogOpen}
        onOpenChange={(open) => setIsDeviceDialogOpen(open)}
      />

      <Overview.Header>
        <h1 className="title">{t('devices.overview')}</h1>

        <div className="flex flex__dir--row flex__ai--center">
          <SearchInput
            onSearchQueryChange={handleSearchQueryChange}
            placeholder={t('devices.searchPlaceholder')}
            id="device-list-search"
            className="m-right-l"
          />
          {isPermissionsFeatureEnabled ? (
            <AccessControlGuard requiredPermissions="ADD_DEVICE">
              <Button
                variant="primary"
                className="p-left-l"
                onClick={() => setIsDeviceDialogOpen(true)}
              >
                <Icon
                  name="plus"
                  size={IconSizeClasses.XSmall}
                  className="bg-white m-right-s"
                />

                {t('addDevice.add')}
              </Button>
            </AccessControlGuard>
          ) : (
            <Button
              variant="primary"
              className="p-left-l"
              onClick={() => setIsDeviceDialogOpen(true)}
            >
              <Icon
                name="plus"
                size={IconSizeClasses.XSmall}
                className="bg-white m-right-s"
              />

              {t('addDevice.add')}
            </Button>
          )}
        </div>
      </Overview.Header>
      <Overview.Body>
        {(routeError || isError) && <LoadingErrorHint />}

        {!isLoading && !isError && (
          <>
            {mergedDevicesData &&
              !isLoading &&
              paginatedData?.pagination &&
              devices?.length > 0 && (
                <>
                  <SortableTable<
                    Device[],
                    Device & { realtime: TelemetryDevice['realtime'] }
                  >
                    data={mergedDevicesData}
                    columns={columns}
                    onRowClick={(device) => handleRowClick(device)}
                    isSelectedRow={(device) =>
                      isValueWithKeyInSearchParams(
                        'serialNumber',
                        device.serialNumber,
                        searchParams,
                      )
                    }
                  />
                  <Pagination
                    paginationData={paginatedData.pagination}
                    onPageSizeChange={(size) => handlePageSizeChange(size)}
                    onPageChange={(newPage) => handlePageChange(newPage)}
                  />
                </>
              )}

            {!isLoading && !isError && (!devices || devices.length === 0) && (
              <div className="flex flex__dir--column flex__ai--center bg-gray-50 p-xxxl">
                <Icon
                  name={searchQuery.length ? 'no-results' : 'generic'}
                  size={IconSizeClasses.Large}
                  className="bg-gray-500 m-bottom-l"
                />

                <p className="m-bottom-m font-weight-medium font-size-xl">
                  {searchQuery?.length > 0
                    ? t('devices.emptySearch')
                    : t('deviceTable.emptyState')}
                </p>
                <p className="m-bottom-xxl w-xl text-align-center">
                  {searchQuery?.length > 0
                    ? t('shared.tryAdjustSearchCriteria')
                    : t('deviceTable.emptyStateHint')}
                </p>
                {!searchQuery?.length && (
                  <Button size="small" onClick={() => setIsDeviceDialogOpen(true)}>
                    <Icon
                      name="plus"
                      className="bg-white m-right-xs"
                      size={IconSizeClasses.Small}
                    />
                    {t('addDevice.add')}
                  </Button>
                )}
              </div>
            )}
          </>
        )}
      </Overview.Body>
    </Overview>
  );
}
