import { Icons } from '@eppendorf/vnls-css/dist';
import { DeviceDTO } from '@eppendorf/vnls-notifications-utils';
import { Icon, IconSizeClasses } from '@eppendorf/vnls-react-components';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import { DeviceNameCell } from '$components/device/name-cell';

import './incident-detail-card-header-content.scss';

export enum IncidentDetailCardHeaderContentType {
  device = 'device',
  initial = 'initial',
  escalated_1 = 'escalated_1',
  escalated_2 = 'escalated_2',
  expired = 'expired',
  acknowledged = 'acknowledged',
}

export type IncidentDetailCardHeaderContentProps = {
  type: IncidentDetailCardHeaderContentType;
  device?: DeviceDTO;
  title?: string;
};

export function IncidentDetailCardHeaderContent({
  type,
  device,
  title,
}: IncidentDetailCardHeaderContentProps) {
  const { t } = useTranslation();

  if (type === IncidentDetailCardHeaderContentType.device && device) {
    return <DeviceNameCell device={device} />;
  }

  const config: Record<
    Exclude<
      IncidentDetailCardHeaderContentType,
      IncidentDetailCardHeaderContentType.device
    >,
    {
      icon: Icons;
      iconBg: string;
      borderColor: string;
      text: string;
      textColor: string;
    }
  > = {
    [IncidentDetailCardHeaderContentType.initial]: {
      icon: 'escalation',
      iconBg: 'bg-blue-500',
      borderColor: 'border-color-blue-500',
      text: t('incidents.initialRecipients'),
      textColor: 'font-weight-bold',
    },
    [IncidentDetailCardHeaderContentType.escalated_1]: {
      icon: 'escalation',
      iconBg: 'bg-orange-500',
      borderColor: 'border-color-orange-500',
      text: t('incidents.incidentsStatus.escalated_1'),
      textColor: 'font-weight-bold',
    },
    [IncidentDetailCardHeaderContentType.escalated_2]: {
      icon: 'escalation',
      iconBg: 'bg-orange-500',
      borderColor: 'border-color-orange-500',
      text: t('incidents.incidentsStatus.escalated_2'),
      textColor: 'font-weight-bold',
    },
    [IncidentDetailCardHeaderContentType.expired]: {
      icon: 'alarm-off',
      iconBg: 'bg-red-500',
      borderColor: 'border-color-red-500',
      text: t('incidents.incidentsStatus.expired'),
      textColor: 'font-weight-bold color-red-500',
    },
    [IncidentDetailCardHeaderContentType.acknowledged]: {
      icon: 'success',
      iconBg: 'incident-detail-card-header--success-icon',
      borderColor: '',
      text: t('incidents.incidentsStatus.acknowledged'),
      textColor: 'font-weight-bold',
    },
  };

  // Type guard to ensure `type` is within the keys of `config`
  if (type in config) {
    const isAcknowledged = type === IncidentDetailCardHeaderContentType.acknowledged;
    const { icon, iconBg, borderColor, text, textColor } =
      config[type as keyof typeof config];

    return (
      <div className="flex flex__ai--center gap-m">
        <div
          className={cn(
            'icon--s flex flex__ai--center flex__jc--center',
            iconBg,
            borderColor,
            {
              'bg-white border-solid border-s border-radius-xxl': !isAcknowledged,
            },
          )}
        >
          <Icon
            name={icon}
            size={isAcknowledged ? IconSizeClasses.Medium : IconSizeClasses.XSmall}
            className={iconBg}
          />
        </div>
        <div className={textColor}>{text}</div>
      </div>
    );
  }

  return null;
}
