import i18next from 'i18next';

export function createColorClassFor(status: string | undefined, element: string): string {
  switch (status?.toLocaleLowerCase?.()) {
    case 'alert':
    case 'error':
      return `${element}-red-500`;
    case 'warn':
      return `${element}-orange-400`;
    default:
      return `${element}-green-500`;
  }
}

export function getSecondsSinceProcessValueChange(timestamp?: number) {
  return timestamp ? (Date.now() - new Date(timestamp).getTime()) / 1000 : 0;
}

export function getFullMinutesSinceProcessValueChange(timestamp?: number) {
  const secondsSinceProcessValueChange = getSecondsSinceProcessValueChange(timestamp);
  return Number((secondsSinceProcessValueChange / 60).toFixed(0));
}

export function getThresholdWarnText(
  thresholdsEnabled: boolean,
  status: string | undefined,
  timestamp: number | undefined,
): string | null {
  const oldValue = !timestamp || getFullMinutesSinceProcessValueChange(timestamp) > 0;

  switch (thresholdsEnabled ? status?.toLocaleLowerCase?.() : 'off') {
    case 'alert':
      return oldValue
        ? i18next.t('translation:alertThresholds.alertThresholdHintOldTimestamp')
        : i18next.t('translation:alertThresholds.alertThresholdHint');
    case 'warn':
      return oldValue
        ? i18next.t('translation:alertThresholds.warnThresholdHintOldTimestamp')
        : i18next.t('translation:alertThresholds.warnThresholdHint');
    case 'off':
      return oldValue
        ? i18next.t('translation:alertThresholds.valueHintOldTimestamp')
        : i18next.t('translation:alertThresholds.valueHint');
    default:
      return oldValue
        ? i18next.t('translation:alertThresholds.defaultThresholdHintOldTimestamp')
        : i18next.t('translation:alertThresholds.defaultThresholdHint');
  }
}
