import type { DeviceConnectionStatus } from '@eppendorf/vnls-inventory-service-types/dist/deviceConnectionStatus';
import { RealtimeData, UnitState } from '@eppendorf/vnls-telemetry-and-events-types';

import { ConnectionStatusBadge } from '$features/devices/connection-status-badge';
import { ActivityStatus } from '$features/monitoring/activity-status/activity-status';
import { findBySubjectAndComponent } from '$features/monitoring/monitoring.utils';

interface DeviceStatusProps {
  realtimeArray: (RealtimeData | null)[];
  deviceStatus?: DeviceConnectionStatus;
  bordered?: boolean;
}

export function DeviceStatus({
  realtimeArray,
  deviceStatus,
  bordered = false,
}: DeviceStatusProps) {
  const status = findBySubjectAndComponent(realtimeArray, 'Unit', 'unitState');
  const activityStatus = (status?.value as UnitState) ?? null;

  return activityStatus === UnitState.Offline ? (
    <ActivityStatus activityStatus={activityStatus} bordered={bordered} />
  ) : (
    <ConnectionStatusBadge status={deviceStatus} bordered={bordered} />
  );
}
