import type {
  IncidentAcknowledgeStatusEnum,
  IncidentDTO,
  IncidentListDTO,
  UpdateReadStatusIncidentBody,
} from '@eppendorf/vnls-notifications-utils';
import {
  keepPreviousData,
  useMutation,
  useQuery,
  useQueryClient,
  useInfiniteQuery,
} from '@tanstack/react-query';
import type { AxiosError } from 'axios';

import { fetcher } from '$shared/fetch';
import { createPathWithQueryParams, INCIDENTS_BASE_PATH } from '$shared/utils/api-paths';

export const incidentsQueryKey = 'incidents';
export const unreadIncidentsCountQueryKey = 'unread-incidents-count';

export const createIncidentsQueryWithQueryParams = () => ({
  queryKey: [incidentsQueryKey],
  queryFn: ({ pageParam }: { pageParam: IncidentListDTO | undefined }) =>
    fetcher.get<IncidentListDTO[]>(
      createPathWithQueryParams(INCIDENTS_BASE_PATH, {
        isLazyLoading: true,
        pageSize: 10,
        ...(pageParam && {
          lastItemId: pageParam.id,
          lastItemCreatedAt: pageParam.createdAt,
        }),
      }),
    ),
  getNextPageParam: (lastPage: IncidentListDTO[], _pages: IncidentListDTO[][]) => {
    if (lastPage.length) {
      return lastPage.at(-1);
    }

    return undefined;
  },
  initialPageParam: undefined,
  staleTime: Infinity,
  placeholderData: keepPreviousData,
});

export const useIncidentsWithQueryParams = () =>
  useInfiniteQuery(createIncidentsQueryWithQueryParams());

export const useUnreadIncidentsCount = (isEnabled: boolean) =>
  useQuery({
    queryKey: [unreadIncidentsCountQueryKey],
    queryFn: () =>
      fetcher.get<{ unreadCount: number }>(`${INCIDENTS_BASE_PATH}/unread-count`),
    placeholderData: keepPreviousData,
    refetchInterval: 4000,
    enabled: isEnabled,
  });

type UpdateIncidentReadStatus = {
  incidentId: string;
  options: UpdateReadStatusIncidentBody;
};

export const useUpdateIncidentReadStatus = () => {
  const queryClient = useQueryClient();
  const updateIncidentReadStatus = ({ incidentId, options }: UpdateIncidentReadStatus) =>
    fetcher.patch<UpdateReadStatusIncidentBody, IncidentDTO>(
      `${INCIDENTS_BASE_PATH}/${incidentId}/actions/update-read-status`,
      options,
    );

  return useMutation<IncidentDTO, AxiosError, UpdateIncidentReadStatus>({
    mutationFn: updateIncidentReadStatus,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [incidentsQueryKey],
      });
      queryClient.invalidateQueries({
        queryKey: [unreadIncidentsCountQueryKey],
      });
    },
  });
};

export const createIncidentQuery = (incidentId: string) => ({
  queryKey: [incidentsQueryKey, incidentId],
  queryFn: () => fetcher.get<IncidentDTO>(`${INCIDENTS_BASE_PATH}/${incidentId}`),
});

export const useGetIncident = (incidentId: string) =>
  useQuery(createIncidentQuery(incidentId));

type AcknowledgeIncident = {
  incidentId: string;
};

export type AcknowledgeIncidentResponse = {
  message: string;
  type: IncidentAcknowledgeStatusEnum;
};

export const useAcknowledgeIncident = () => {
  const queryClient = useQueryClient();
  const acknowledgeIncident = ({ incidentId }: AcknowledgeIncident) =>
    fetcher.patch<null, IncidentDTO | AcknowledgeIncidentResponse>(
      `${INCIDENTS_BASE_PATH}/${incidentId}/actions/acknowledge`,
      null,
    );

  return useMutation<
    IncidentDTO | AcknowledgeIncidentResponse,
    AxiosError,
    AcknowledgeIncident
  >({
    mutationFn: acknowledgeIncident,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [incidentsQueryKey],
      });
      queryClient.invalidateQueries({
        queryKey: [unreadIncidentsCountQueryKey],
      });
    },
  });
};
