import {
  IncidentAcknowledgeStatusEnum,
  IncidentStatusEnum,
} from '@eppendorf/vnls-notifications-utils';
import { useCallback, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useDebounce, useUnmount } from 'react-use';

import {
  IncidentAcknowledgedDialog,
  IncidentAcknowledgedScreens,
} from '$features/incidents/incident-acknowledged-dialog/incident-acknowledged-dialog';
import { IncidentDetailAcknowledgeFooter } from '$features/incidents/incident-detail-acknowledge-footer';
import { IncidentDetailAcknowledgedContent } from '$features/incidents/incident-detail-acknowledged-content';
import { IncidentDetailCard } from '$features/incidents/incident-detail-card';
import {
  IncidentDetailCardHeaderContent,
  IncidentDetailCardHeaderContentType,
} from '$features/incidents/incident-detail-card-header-content';
import { IncidentDetailGroupContent } from '$features/incidents/incident-detail-group-content';
import { IncidentDetailMessagesContent } from '$features/incidents/incident-detail-messages-content';
import {
  AcknowledgeIncidentResponse,
  useAcknowledgeIncident,
  useGetIncident,
  useUpdateIncidentReadStatus,
} from '$features/incidents/incidents.api';

export function IncidentFlow() {
  const [searchParams] = useSearchParams();
  const incidentId = searchParams.get('id') || '';
  const { data } = useGetIncident(incidentId);
  const { mutate } = useUpdateIncidentReadStatus();
  const {
    mutate: acknowledgeIncident,
    isPending: acknowledgingIncident,
    data: acknowledgedIncidentData,
  } = useAcknowledgeIncident();

  const [isAcknowledgedDialogOpen, setIsAcknowledgedDialogOpen] =
    useState<boolean>(false);

  const [acknowledgmentScreen, setAcknowledgmentScreen] =
    useState<IncidentAcknowledgedScreens>(IncidentAcknowledgedScreens.SUCCESS);

  const [, cancel] = useDebounce(
    () => {
      if (!data?.isRead) {
        mutate({ incidentId, options: { markAsRead: true } });
      }
    },
    1000,
    [data],
  );

  useUnmount(cancel);

  const handleAcknowledgeIncident = useCallback(() => {
    acknowledgeIncident(
      { incidentId },
      {
        onSuccess: (response) => {
          if (
            (response as AcknowledgeIncidentResponse).type ===
            IncidentAcknowledgeStatusEnum.AlreadyAcknowledged
          ) {
            // we are leaving out the "already acknowledged" dialog.
          } else {
            setAcknowledgmentScreen(IncidentAcknowledgedScreens.SUCCESS);
            setIsAcknowledgedDialogOpen(true);
          }
        },
        onError: () => {
          setAcknowledgmentScreen(IncidentAcknowledgedScreens.FAILURE);
          setIsAcknowledgedDialogOpen(true);
        },
      },
    );
  }, [incidentId, acknowledgedIncidentData]);

  const toggleAcknowledgedDialog = useCallback(
    (value: boolean) => setIsAcknowledgedDialogOpen(value),
    [],
  );

  const renderTimeline = useMemo(() => {
    if (!data) return null;

    const notificationMessage = data?.notificationMessages[0];

    return (
      <>
        <IncidentDetailCard
          deviceCard
          header={
            <IncidentDetailCardHeaderContent
              type={IncidentDetailCardHeaderContentType.device}
              device={data.device!}
            />
          }
        >
          {notificationMessage ? (
            <IncidentDetailMessagesContent
              notificationMessage={data?.notificationMessages[0]}
            />
          ) : null}
        </IncidentDetailCard>
        <IncidentDetailCard
          header={
            <IncidentDetailCardHeaderContent
              type={IncidentDetailCardHeaderContentType.initial}
            />
          }
          showTail={!!data?.acknowledgedAt}
        >
          {data?.notificationGroups.map((notificationGroup, i) => (
            <IncidentDetailGroupContent
              key={`notification-group-${i.toString()}`}
              notificationGroup={notificationGroup}
            />
          ))}
        </IncidentDetailCard>
        {!!data?.acknowledgedAt && (
          <IncidentDetailCard
            variant="success"
            header={
              <IncidentDetailCardHeaderContent
                type={IncidentDetailCardHeaderContentType.acknowledged}
              />
            }
            showTail={false}
          >
            <IncidentDetailAcknowledgedContent
              acknowledgedAt={data?.acknowledgedAt}
              acknowledgedBy={`${data?.acknowledgedBy?.firstName} ${data?.acknowledgedBy?.lastName}`}
            />
          </IncidentDetailCard>
        )}
      </>
    );
  }, [data, data?.acknowledgedAt]);

  return (
    <>
      {renderTimeline}
      {data?.canAcknowledge &&
        [
          IncidentStatusEnum.ActionRequired,
          IncidentStatusEnum.Escalated_1,
          IncidentStatusEnum.Escalated_2,
        ].includes(data.incidentStatus as IncidentStatusEnum) && (
          <IncidentDetailAcknowledgeFooter
            acknowledgeIncident={handleAcknowledgeIncident}
            acknowledgingIncident={acknowledgingIncident}
          />
        )}

      <IncidentAcknowledgedDialog
        isOpen={isAcknowledgedDialogOpen}
        onOpenChange={toggleAcknowledgedDialog}
        recipientCount={data?.notificationGroups[0]?.recipients.length ?? 0}
        screen={acknowledgmentScreen}
        onRetry={handleAcknowledgeIncident}
        acknowledgingIncident={acknowledgingIncident}
      />
    </>
  );
}
