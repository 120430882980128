import { DeviceTypes } from '@eppendorf/vnls-application-configuration-service-types';
import { Icons } from '@eppendorf/vnls-css/dist';
import { Device } from '@eppendorf/vnls-inventory-service-types';
import { Device as RealtimeDevice } from '@eppendorf/vnls-telemetry-and-events-types/dist/generated/types';
import { t } from 'i18next';

import { useDeviceType } from '$components/app-config/useDeviceType';
import { useDeviceTypeConfig } from '$components/app-config/useDeviceTypeConfig';
import { useFeatureIsEnabled } from '$components/feature-toogle/feature-is-enabled';
import { TabNavigationRoute } from '$components/tabs/tabs.types';
import { searchDeviceIcon } from '$features/devices/devices.utils';

export interface DeviceViewConfig {
  deviceIcon: Icons;
  deviceDetailSidecarRoutes: TabNavigationRoute[];
  showBattery: boolean;
  showSignalStrength: boolean;
}

/**
 * Temporary solution to mock sense device types until the backend is available
 */
export function useDeviceViewConfig(device?: Device | RealtimeDevice): DeviceViewConfig {
  const deviceDetailsRoute: TabNavigationRoute = {
    label: t('deviceDetail.tabs.details'),
    path: 'details',
    icon: 'info',
  };
  const sidecarDeviceViewConfig: DeviceViewConfig = {
    deviceIcon: 'generic',
    deviceDetailSidecarRoutes: [deviceDetailsRoute],
    showBattery: false,
    showSignalStrength: false,
  };

  if (useFeatureIsEnabled('updateDeviceNotes')) {
    sidecarDeviceViewConfig.deviceDetailSidecarRoutes.push({
      label: t('deviceDetail.tabs.notes'),
      path: 'notes',
      icon: 'document', // TODO: replace with correct icon
    });
  }

  const deviceType = useDeviceType(device) as Icons;
  const deviceTypeConfig = useDeviceTypeConfig(device);

  // fallback to generic device if no device is provided
  if (!device) return sidecarDeviceViewConfig;

  const deviceIcon = searchDeviceIcon(deviceType);

  sidecarDeviceViewConfig.deviceIcon = deviceIcon;
  sidecarDeviceViewConfig.showBattery = !!deviceTypeConfig?.operational?.isChargeable;
  sidecarDeviceViewConfig.showSignalStrength =
    !!deviceTypeConfig?.operational?.isWireless;

  switch (deviceTypeConfig?.type) {
    case DeviceTypes.MONITOR:
      sidecarDeviceViewConfig.deviceDetailSidecarRoutes.push({
        label: t('deviceDetail.tabs.associatedRouter'),
        path: 'associatedRouter',
        icon: DeviceTypes.GATEWAY,
      });
      break;
    case DeviceTypes.GATEWAY:
      sidecarDeviceViewConfig.deviceDetailSidecarRoutes.push({
        label: t('deviceDetail.tabs.associatedMonitors'),
        path: 'associatedMonitors',
        icon: DeviceTypes.MONITOR,
      });
      break;
    case DeviceTypes.VNBOX:
      sidecarDeviceViewConfig.deviceDetailSidecarRoutes.push({
        label: t('deviceDetail.tabs.associatedDevices'),
        path: 'associatedDevices',
        icon: 'generic',
      });
      break;
    default:
      break;
  }

  return sidecarDeviceViewConfig;
}
