import { ActivityCode, EntityType } from '@eppendorf/vnls-audit-event-client';
import {
  Button,
  Dialog,
  DialogProps,
  Icon,
  IconSizeClasses,
} from '@eppendorf/vnls-react-components';
import { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { useCommitAuditEvent } from '$shared/audit-log';
import { isFeatureEnabled } from '$shared/feature-toggle/is-feature-enabled';
import { createAuditEvent } from '$shared/utils/create-audit-event';

import { postGatewayUpdateStatus } from '$features/devices/devices.api';
import { DeviceUpdateStatus } from '$features/devices/devices.types';

type UpdateDialogProps = Pick<DialogProps, 'isOpen' | 'onOpenChange'> & {
  updateStatus: DeviceUpdateStatus;
};

export function UpdateGatewayDialog({
  isOpen = false,
  onOpenChange,
  updateStatus,
}: UpdateDialogProps) {
  const { t } = useTranslation();

  const { mutate: update, isPending } = postGatewayUpdateStatus();

  const affectedDevices = updateStatus.devices.map((device) => ({
    type: EntityType.Device,
    id: device.serialNumber ?? '',
  }));

  const devicesUpdated = updateStatus.devices.map((device) => ({
    id: device.serialNumber ?? '',
    oldVersion: device.softwareRevision,
    newVersion: updateStatus.appliedSoftwareVersion,
  }));

  const { mutate: commit } = useCommitAuditEvent();
  const commitAuditEventEnabled = isFeatureEnabled('commitAuditEvent');

  const updateDevices = async () => {
    if (commitAuditEventEnabled) {
      const auditEvent = await createAuditEvent(ActivityCode.DEVICE_UPDATE_STARTED);
      const event = auditEvent.withAffected(affectedDevices).withMetadata({
        v: '1',
        devicesUpdated,
      });
      commit(event);
    }
    update();
  };

  useEffect(() => {
    if (!isPending && onOpenChange) {
      onOpenChange(false);
    }
  }, [isPending]);

  const getDevicesToUpdate = () => (
    <div className="m-y-4xl h-max-s overflow-scroll">
      {updateStatus.devices.map((device) => (
        <div key={`device-update-${device.name}`} className="flex flex__ai--center">
          <Icon size={IconSizeClasses.Small} name="sense-gateway" />

          <div className="m-left-s text-align-left">
            <div className="body-bold">
              {device.name || t('deviceDetail.fallbackHeadlineNameEmpty')}
            </div>
            <div className="color-gray-800">{device.model}</div>
          </div>
        </div>
      ))}
    </div>
  );

  return (
    <Dialog
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      className="w-6xl w-max-6xl"
      hideCloseButton
    >
      <div className="flex flex__dir--column flex__ai--center">
        {isPending && <div className="loader" />}
        <Icon
          name="info"
          className="bg-blue-500 m-bottom-xxl"
          size={IconSizeClasses.XLarge}
        />
        <Dialog.Title>
          {t('deviceDetail.deviceInformation.updateGatewayDialog.title')}
        </Dialog.Title>
        <Dialog.Description className="m-bottom-xxs">
          <div>
            <Trans
              i18nKey="deviceDetail.deviceInformation.updateGatewayDialog.description"
              components={{ bold: <span className="font-weight-bold" /> }}
            />
          </div>
          {getDevicesToUpdate()}
          <div className="m-top-xl">
            <Trans
              i18nKey="deviceDetail.deviceInformation.updateGatewayDialog.disclaimer"
              components={{ bold: <span className="font-weight-bold" /> }}
            />
          </div>
        </Dialog.Description>
      </div>
      <Dialog.Actions className="m-top-xxl">
        <Dialog.Close>
          <Button variant="secondary">{t('shared.later')}</Button>
        </Dialog.Close>
        <Button onClick={updateDevices} disabled={isPending}>
          {t('shared.updateNow')}
        </Button>
      </Dialog.Actions>
    </Dialog>
  );
}
