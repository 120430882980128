import { CardBody } from '$components/card/card-body';
import { CardFooter } from '$components/card/card-footer';
import { CardTail } from '$components/card/card-tail';

import { InternalCard } from './card';
import { CardHeader } from './card-header';

type CardType = typeof InternalCard;

type CompoundedComponent = CardType & {
  Header: typeof CardHeader;
  Body: typeof CardBody;
  Tail: typeof CardTail;
  Footer: typeof CardFooter;
};

const Card = InternalCard as CompoundedComponent;

Card.Header = CardHeader;
Card.Body = CardBody;
Card.Tail = CardTail;
Card.Footer = CardFooter;

export { Card };
