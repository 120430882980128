import { Badge } from '@eppendorf/vnls-react-components';

import {
  getFullMinutesSinceProcessValueChange,
  getSecondsSinceProcessValueChange,
} from '$features/monitoring/parameters/utils';

export interface MonitoringParameterValueAgeProps {
  timestamp?: number;
}

export function MonitoringParameterValueAge({
  timestamp,
}: MonitoringParameterValueAgeProps) {
  const secondsSinceProcessValueChange = getSecondsSinceProcessValueChange(timestamp);

  const className = 'm-bottom-xxs';
  const size = 'small';

  const twoMinutes = 120;
  if (!timestamp || secondsSinceProcessValueChange < twoMinutes) {
    return null;
  }

  const minutesSinceProcessValueChange = getFullMinutesSinceProcessValueChange(timestamp);

  // if (minutesSinceProcessValueChange === 0) {
  //   return (
  //     <Badge size="small" className="m-bottom-m">
  //       Last updated just recently
  //     </Badge>
  //   );
  // }

  // value is greater than 1 year
  if (minutesSinceProcessValueChange > 525960) {
    return (
      <Badge size={size} className={className}>
        {`Last updated ${new Intl.RelativeTimeFormat('en', { style: 'short' }).format(
          -(minutesSinceProcessValueChange / 525960).toFixed(),
          'years',
        )}`}
      </Badge>
    );
  }

  // value is greater than 1 month
  if (minutesSinceProcessValueChange > 60 * 24 * 30) {
    return (
      <Badge size={size} className={className}>
        {`Last updated ${new Intl.RelativeTimeFormat('en', { style: 'short' }).format(
          -(minutesSinceProcessValueChange / 60 / 24 / 30).toFixed(),
          'months',
        )}`}
      </Badge>
    );
  }

  // value is greater than a week
  if (minutesSinceProcessValueChange > 60 * 24 * 7) {
    return (
      <Badge size={size} className={className}>
        {`Last updated ${new Intl.RelativeTimeFormat('en', { style: 'short' }).format(
          -(minutesSinceProcessValueChange / 60 / 24 / 7).toFixed(),
          'weeks',
        )}`}
      </Badge>
    );
  }

  // value is greater than a day
  if (minutesSinceProcessValueChange > 60 * 24) {
    return (
      <Badge size={size} className={className}>
        {`Last updated ${new Intl.RelativeTimeFormat('en', { style: 'short' }).format(
          -(minutesSinceProcessValueChange / 60 / 24).toFixed(),
          'days',
        )}`}
      </Badge>
    );
  }

  // value is greater than an hour
  if (minutesSinceProcessValueChange > 60) {
    return (
      <Badge size={size} className={className}>
        {`Last updated ${new Intl.RelativeTimeFormat('en', { style: 'short' }).format(
          -(minutesSinceProcessValueChange / 60).toFixed(),
          'hours',
        )}`}
      </Badge>
    );
  }

  return (
    <Badge size={size} className={className}>
      {`Last updated ${new Intl.RelativeTimeFormat('en', { style: 'short' }).format(
        -minutesSinceProcessValueChange,
        'minutes',
      )}`}
    </Badge>
  );
}
