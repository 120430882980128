import { type NotificationGroupDTO } from '@eppendorf/vnls-notifications-utils';
import { Icon, IconSizeClasses } from '@eppendorf/vnls-react-components';
import { useTranslation } from 'react-i18next';

import { getFormattedLocalizedDateTimeWithSeconds } from '$shared/utils/date.utils';

type IncidentFlowItemProps = {
  notificationGroup: NotificationGroupDTO;
};

export function IncidentDetailGroupContent({ notificationGroup }: IncidentFlowItemProps) {
  const { t } = useTranslation();

  return (
    <div className="flex gap-l flex__dir--column flex__ai--center">
      {(notificationGroup.firstNotificationAt ||
        notificationGroup.lastNotificationAt ||
        notificationGroup.notificationCountInBetween) && (
        <div className="flex gap-m flex__dir--column text-align-center">
          {notificationGroup.firstNotificationAt && (
            <div>
              {getFormattedLocalizedDateTimeWithSeconds(
                notificationGroup.firstNotificationAt,
              )}
            </div>
          )}
          {notificationGroup.notificationCountInBetween > 0 && (
            <>
              <div className="flex flex__ai--center">
                <Icon name="messages-divider-line" size={IconSizeClasses.XSmall} />
                <span className="font-weight-medium">
                  {t('incidents.incidentsCounter', {
                    count: notificationGroup.notificationCountInBetween,
                  })}
                </span>
              </div>
              {notificationGroup.lastNotificationAt && (
                <div>
                  {getFormattedLocalizedDateTimeWithSeconds(
                    notificationGroup.lastNotificationAt,
                  )}
                </div>
              )}
            </>
          )}
        </div>
      )}
      {notificationGroup.recipients?.length > 0 && (
        <div className="flex gap-s flex__dir--column">
          {notificationGroup.recipients.map((recipient) => (
            <div key={recipient.id} className="font-weight-semibold">
              {recipient.firstName} {recipient.lastName}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
